import lsApi from "../api/lsApi";
import { useAppSelector } from '../redux/hooks';
import { IPaymentStatement } from '../models/payments';

export function useEstadoCuenta() {
  const userData = useAppSelector((state) => state.auth.userData);
  const { nombre, usuario } = userData!;
  const propietario = nombre;
  const casa = usuario.slice(2);
  const newDate = new Date();
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const fecha = `${newDate.getFullYear()}-${month}-01`;

  const getEstadoCuenta = async () => {
    try {
      const { status, data } = await lsApi.get<IPaymentStatement[]>(`/api/ControlAcceso/EstadoCuenta?DAFECHAI=${fecha}&VACASA=${usuario}`);
      if (status === 200) {
        return data;
      } else {
        return [
          {
            id: status.toString(),
            fecha,
            descripcion: `status ${status}`,
            type: 'abono',
            monto: status
          }
        ];
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return {
    getEstadoCuenta,
    propietario,
    casa,
    fecha
  };
}