import React from 'react'
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import { MuiFileInput } from 'mui-file-input'
import { useState, useRef, useCallback } from 'react';
import { CameraAlt, Home} from '@mui/icons-material';
import { CustomModal } from '../components/Modal';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import useIncidentes from '../hooks/useIncidentes';
import Webcam from 'react-webcam';


export const Incidentes = () => {

    const navigate = useNavigate()
    const {createIncidente} = useIncidentes()
    const {casa,nombre} = useAppSelector((state) => state.auth.userData)!

    const webcamRef = useRef(null) as any

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [file, setFile] = useState(null) as any


    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
        if (!error) navigate('/home')
    }

    const handleCapture = useCallback(() => {
        const imageSrc = webcamRef.current!.getScreenshot({width:1920, height:1080})
        // Convert base64 to file ans set filename to incidente.png
        const byteString = atob(imageSrc.split(',')[1])
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }
        const file = new File([ab], 'incidente.png', {type: 'image/png'})
        setFile(file)
        setModalMessage('Foto capturada con exito')
        setError(true)
        handleOpen()

      },
      [webcamRef]
    )

    const handleChange = async (file: any) => {
        if (!file){
            setFile(null)   
            return
        } 
        setModalMessage('Foto agregada con exito')
        setError(true)
        setFile(file)
        handleOpen()
    }

    const handleSubmit = async ( e : any ) => {
        e.preventDefault()
        const { tipo, nota } = e.target.elements

        if(file === null) {
            setModalMessage('Debe agregar una foto del incidente')
            setError(true)
            handleOpen()
            return
        }

        setLoading(true)
        
        let code = await createIncidente(tipo.value, nota.value, file)
        if (code) {
            setModalMessage('Incidente creado con exito')
            setError(false)
        }
        else {
            setModalMessage('Error al crear el incidente')
            setError(true)
        }

        setLoading(false)
        handleOpen()
    }

    return (
        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>

            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Crear Incidente
            </Typography>

            <Box component="form" display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={500} onSubmit={handleSubmit} >

                <TextField fullWidth required disabled label="Casa" variant="outlined" sx={{marginY:2}}  value={`${casa} - ${nombre}`} />

                <TextField fullWidth required select name='tipo' label="Tipo" variant="outlined" sx={{marginY:2}}
                    SelectProps={{native: true}} defaultValue="1"
                >
                    <option value='1'>Garantía</option>
                    <option value='2'>Queja</option>
                    <option value='3'>Denuncia</option>
                    <option value='4'>Sugerencia</option>
                    <option value='5'>Otro</option>
                </TextField>

                <TextField multiline rows={2} fullWidth required label="Descripcion" name='nota' variant="outlined" sx={{marginY:2}} 
                    InputProps={{
                        inputProps: {
                            maxLength: 150
                        }
                    }}
                />

                <MuiFileInput fullWidth value={file} onChange={handleChange} inputProps={{ accept: '.png, .jpeg' }} 
                    placeholder="Foto de Incidente" sx={{marginY:2}}
                />

                <Webcam
                    audio={false}
                    height={250}
                    ref={webcamRef}
                    screenshotFormat="image/png"
                    videoConstraints={{
                        facingMode: { exact: "environment" }
                    }}
                />

                <Button type='button' color='success' size='large' variant="contained" endIcon={<CameraAlt/>} sx={{marginY:2 , width:'50%'}} onClick={handleCapture} > Capturar </Button>

                <Button type='submit' color='secondary' size='large' variant="contained"  sx={{marginY:2 , width:'50%'}}
                    disabled={loading}
                    endIcon={ loading ? <CircularProgress size={25} /> : <SendIcon/> }
                > OK </Button>

            </Box>

            <CustomModal
                openState={openModal}
                onCloseHandler={handleClose}
            >
                
                <Typography variant="h6">
                    Incidentes
                </Typography>

                    <Typography variant="h6" component="div" gutterBottom>
                        {modalMessage}
                    </Typography>

                {!error && 
                <Button color='secondary' variant="outlined" endIcon={<Home/>}  sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    Inicio
                </Button>}

                {error && 
                <Button color='secondary' variant="outlined" sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    OK
                </Button>}

            </CustomModal>
            
        </Box>
    )
}
