import React from 'react';
import { Notifications } from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import './Notificaciones.css';

export const Notificaciones = ({ action }) => {
  return (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 60,
        height: 60,
        marginY: 1,
        marginRight: -10
    }}>
        <IconButton
            size="large"
            aria-controls="menu-appbar"
            // aria-haspopup="true"
            onClick={() => action()}
            // style={{ border: '1px solid white' }}
            color="inherit"
            // className={`Notificacion__Button ${publicaciones.length > 0 ?  'Sin-Leer' : 'Leidas'}`}
            className={`Notificacion__Button Sin-Leer`}
        >
          <Notifications />
        </IconButton>
    </Box>
  )
}