import lsApi from "../api/lsApi";
import { useAppSelector } from '../redux/hooks';

export default function useIncidentes() {

    const userData = useAppSelector((state) => state.auth.userData)

    const createIncidente = async (motivo:string,nota:string, file:any) => {

        const {empresa,usuario,agencia,casa} = userData!

        const formData = new FormData()
        formData.append('Empresa', empresa.toString())
        formData.append('Origen', '3')
        formData.append('Destino', '11')
        formData.append('Param1', `${agencia}|${casa}|${motivo}`)
        formData.append('Param2', nota)
        formData.append('Usuario', usuario)
        formData.append('File_Name', 'img01')
        formData.append('File', file)

        try{
            const { data, status } = await lsApi.post('/api/Core/UploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (status === 200) {
                return data
            }
            else{
                return false
            }
            
        }
        catch (error) {
            return false
        }

    }

    return {
        createIncidente,
    }

}

