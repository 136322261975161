import React, { useEffect, useState } from 'react'
import { Box, Button, FormControlLabel, Switch, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import { CustomModal } from '../components/Modal';
import { Home } from '@mui/icons-material';
import { useInmuebles } from '../hooks/useInmuebles';
import { IInmueble } from '../models/inmuebles';
import { TableHeaderCell } from '../components/Table/TableHeaderCell';
import { TableCell } from '../components/Table/TableCell';


export const ControlInmuebles = () => {


    const { getInmuebles, setActivacionInactivacion, resetPassword } = useInmuebles()

    const [inmueblesList, setInmueblesList] = useState<IInmueble[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const handleActivarDesactivar = async (idCasa : number) => {
        let res = await setActivacionInactivacion(idCasa)
        if (res) {
            setModalMessage(res.message)
            setInmueblesList(inmueblesList.map( inmueble => inmueble.casa === idCasa ? {...inmueble, estado: inmueble.estado === 0 ? 1 : 0} : inmueble ))
        }
        else{
            setModalMessage('Error al activar/desactivar el inmueble')
        }
        handleOpen()
    }

    const handleRecuperarPassword = async ( idCasa : number) => {
        let res = await resetPassword(idCasa)
        if (res) {
            setModalMessage("El nuevo password es: " + res.message)
        }
        else{
            setModalMessage('Error al activar/desactivar el inmueble')
        }
        handleOpen()
    }
    

    useEffect(() => {
        (async () => {
            let res = await getInmuebles()
            if (res) {
                setInmueblesList(res)
            }
            else{
                setModalMessage('Error al obtener el listado de inmuebles')
                handleOpen()
            }
        })()    

    }, [])

    return (
        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>

            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Inmuebles
            </Typography>

            <Box display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={800} >

            <Table stickyHeader>
                <TableHead>
                    <TableRow >
                        <TableHeaderCell>No. Casa</TableHeaderCell>
                        <TableHeaderCell>Nombre</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inmueblesList.length > 0 && inmueblesList.map((inmueble, index) => (
                        <TableRow key={index}>
                            <TableCell>{inmueble.num_casa}</TableCell>
                            <TableCell>{inmueble.nombre}</TableCell>
                            <TableCell>
                                <FormControlLabel
                                    value="top"
                                    control={<Switch color="primary" checked={inmueble.estado === 1} onChange={ () => handleActivarDesactivar(inmueble.casa)} />}
                                    label={inmueble.estado === 0 ? 'Inactivo' : 'Activo'}
                                    labelPlacement="top"
                                />
                            </TableCell>
                            <TableCell>
                                <Button color='warning' size='small' variant="outlined" onClick={ () => handleRecuperarPassword(inmueble.casa)}> Generar Nuevo Password </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            </Box>

            <CustomModal
                openState={openModal}
                onCloseHandler={handleClose}
            >
                <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                    {modalMessage}
                </Typography>

                <Button color='secondary' variant="outlined" sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    OK
                </Button>

            </CustomModal>
            

        </Box>
    )
}
