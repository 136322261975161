import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useLogin from '../hooks/useLogin'
import { useAppSelector } from '../redux/hooks';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const Login = () => {

  const { loginHandler, validateToken } = useLogin()
  const [offlineSnack, setOfflineSnack] = useState(false)
  const [onlineSnack, setOnlineSnack] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate()
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  
  useEffect(() => {

    if (isAuthenticated) {
      navigate('/home')
    }

  }, [isAuthenticated])

  useEffect(() => {

    (async () => {
      await validateToken()
    })()

    window.addEventListener('offline', () => setOfflineSnack(true))
    window.addEventListener('online', () => setOnlineSnack(true))

    navigator.onLine ? setOnlineSnack(true) : setOfflineSnack(true)
    
    return () => {
      window.removeEventListener('offline', () => setOfflineSnack(true))
      window.removeEventListener('online', () => setOnlineSnack(true))
    }

  }, [])

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    const { userName, password } = e.target.elements
    await loginHandler(userName.value, password.value)
  }

  return (
    <>
    <Box overflow="hidden" position='absolute' top='0' right='0' left='0' bottom='0' >
    <Box
      style={{
        backgroundColor: 'rgba(255, 204, 0, 1)',
        zIndex: -2,
        position: 'absolute',
        height: '200vh',
        width: '200vw',
        transform: 'rotate(45deg)',
        top: '15vw',
        left: '60vw',
      }}
    />

    <Box
      style={{
        backgroundColor: 'rgba(1, 60, 120, 1)',
        zIndex: -1, 
        position: 'absolute',
        height: '200vh',
        width: '200vw',
        transform: 'rotate(-45deg)',
        top: '45vw',
        right: '30vw',
      }}
    />
    
    <Box alignItems="center" justifyContent="center" display="flex" flexDirection="row" height={'90vh'}  > 


      <Box alignItems="center" justifyContent="center" flexDirection="column" width={1} marginX={10} height={0.85}
        border={2} borderColor='black' borderRadius={4} display={{ xs: 'none', lg: 'flex' }}
      >

        <Typography variant="h2" component="div" gutterBottom>
          Bienvenido
        </Typography>

        <img src='assets\edificio-icono.png' alt='LogicSoft Logo' width='50%' height='50%'/>
        
      </Box>


      <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column" width={1}>

        <Box display='flex' justifyContent='center' marginY='2' width='10em'>
            <img src='assets\LogicSoft.png' alt='LogicSoft Logo' width='75%' height='75%'/>
        </Box>

        <Typography variant="h4" component="div" gutterBottom>
          Iniciar Sesión
        </Typography>

        <Box component="form" justifyContent="center" alignItems="center" display="flex" flexDirection="column"
          width={0.8} maxWidth={400} mt={2} onSubmit={handleSubmit}
        >

          <TextField fullWidth name='userName' label="Usuario" type='text' variant="outlined" sx={{marginY:2}} />

          <FormControl fullWidth sx={{marginY:2}} variant="outlined">
            <InputLabel>Contraseña</InputLabel>
            <OutlinedInput
              type={showPassword ? 'text' : 'password'}
              name='password' 
              label="Contraseña"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Button variant="contained" type='submit' sx={{marginY:2}} > OK </Button>
              
        </Box>

      </Box>

    </Box>

    </Box>


    <Typography variant="h6" component="div" position='absolute' bottom='0' right='0' fontSize={20}>
      v1.8{window.location.origin === 'https://logicsoft-app-testing.netlify.app' ? '-dev' : ''}
    </Typography>

    <Snackbar open={onlineSnack} autoHideDuration={5000} onClose={ () => setOnlineSnack(false) } anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
      <Alert onClose={ () => setOnlineSnack(false) } severity="success" sx={{ width: '100%', fontSize: '1.25em' }}>
        Conexión establecida con el servidor
      </Alert>
    </Snackbar>

    <Snackbar open={offlineSnack} autoHideDuration={5000} onClose={ () => setOfflineSnack(false) } anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={ () => setOfflineSnack(false) } severity="error"  sx={{ width: '100%', fontSize: '1.25em' }}>
        No se pudo establecer conexión con el servidor
      </Alert>
    </Snackbar>

    </>
  )
}
