import lsApi from "../api/lsApi";
import { IInmueble, IActionInmuebleResponse } from '../models/inmuebles';
import { IHistoricoLecturasAgua } from '../models/lecturas';
import { useAppSelector } from '../redux/hooks';

export function useInmuebles() {

    const userData = useAppSelector((state) => state.auth.userData)
    const {agencia,empresa,usuario} = userData!

    const getInmuebles = async () => {

        try{
            const { data, status } = await lsApi.get<IInmueble[]>('/api/ControlAcceso/ListadoInmuebles')
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }

    }

    const setActivacionInactivacion = async ( idCasa : number) => {

        try{
            const { data, status } = await lsApi.put<IActionInmuebleResponse>('/api/ControlAcceso/ActivarInmueble', {
                empresa,
                agencia,
                iD_Casa : idCasa,
                usuario,
            })
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }

    }

    const resetPassword = async ( idCasa : number) => {

        try{
            const { data, status } = await lsApi.put<IActionInmuebleResponse>('/api/ControlAcceso/CambiarPassword', {
                empresa,
                agencia,
                iD_Casa : idCasa,
                usuario,
            })
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }

    }


    return {
        getInmuebles,
        setActivacionInactivacion,
        resetPassword
    }
}