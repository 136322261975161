import React from 'react';
import './Alert.css';

export interface IAlertProps {
  type?: string;
  message: string;
  okText?: string;
  show: boolean;
  setShow: (show: boolean) => void;
}

const traducciones = {
  warning: 'advertencia',
  error: 'error',
  success: 'éxito',
  info: 'info',
}

export const Alert = ({ type = 'info', message, okText, show, setShow }: IAlertProps) => {
  return (
    <div className={`Alert ${show ? 'active' : 'inactive'}`}>
      <div className='Alert__Content'>
        <div className={`Alert__Header ${type}`}>
          <div className='Header__Title'>{traducciones[type]}</div>
        </div>
        <div className='Alert__Body'>
          <div className='Body__Message'>{message}</div>
        </div>
        <div className='Alert__Buttons'>
            <button className='Button' onClick={() => setShow(false)}>
              {okText ? okText : 'Aceptar'}
            </button>
          </div>
      </div>
    </div>
  );
}