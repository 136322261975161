import React from 'react';
import './Toast.css';

interface IToastProps {
  message: string;
  type?: string;
}

export const Toast = ({message, type = ''}: IToastProps) => {
  return (
    <div className={`Toast ${type}`}>
      <div className='Toast__Content'>
        {message}
      </div>
    </div>
  )
}