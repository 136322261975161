import React, { useEffect, useState } from 'react'
import { Box, Button, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import useAccessCode from '../hooks/useAccessCode';
import { useNavigate } from 'react-router-dom';
import { IHistoryAccessCode } from '../models/codes';
import { CustomModal } from '../components/Modal';
import { Home } from '@mui/icons-material';
import { convertDate } from '../utils/date';
import { TableHeaderCell } from '../components/Table/TableHeaderCell';
import { TableCell } from '../components/Table/TableCell';

export const HistorialAcceso = () => {

    const navigate = useNavigate()

    const { getHistory } = useAccessCode()

    const [history, setHistory] = useState<IHistoryAccessCode[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
        navigate('/home')
    };
    

    useEffect(() => {
        (async () => {
            let history = await getHistory()
            if (history) {
                setHistory(history)
            }
            else{
                setModalMessage('Error al obtener el historial')
                handleOpen()
            }
        })()    

    }, [])

    return (
        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>

            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Historial de Accesos
            </Typography>

            <Box display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={800} >

            <Table stickyHeader>
                <TableHead>
                    <TableRow >
                        <TableHeaderCell>Codigo</TableHeaderCell>
                        <TableHeaderCell>Nombre Visita/Evento</TableHeaderCell>
                        <TableHeaderCell>Fecha Ingreso</TableHeaderCell>
                        <TableHeaderCell>Fecha Salida</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {history.length > 0 && history.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.codigo_Acceso}</TableCell>
                            <TableCell>{item.nombre_Visita}</TableCell>
                            <TableCell>{convertDate(item.fecha_Ingreso)}</TableCell>
                            <TableCell>{convertDate(item.fecha_Salida)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            </Box>

            
                

            

            <CustomModal
                openState={openModal}
                onCloseHandler={handleClose}
            >
                <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                    {modalMessage}
                </Typography>

                <Button color='secondary' variant="outlined" endIcon={<Home/>} sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    Inicio
                </Button>

            </CustomModal>
            

        </Box>
    )
}
