import React, { useEffect, useState } from 'react';
import { ListEncuestas } from './ListEncuestas';
import { DetalleEncuesta } from './DetalleEncuesta';
import { useEncuestas } from '../../hooks/useEncuestas';
import { IListEncuesta } from '../../models/encuestas';
import { Loader } from '../../components/Loader';

export const Encuestas = () => {
  const [listadoEncuestas, setListadoEncuestas] = useState<IListEncuesta[]>([]);
  const [idEncuesta, setIdEncuesta] = useState<number>(-1);
  const [renderList, setRenderList] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getListEncuestas } = useEncuestas();

  useEffect(() => {
    setIsLoading(true);
    getListEncuestas()
    .then(data => {
      setIsLoading(false);
      setListadoEncuestas(data);
    })
    .catch(err => console.error(err));
  }, []);

  const handleSelectEncuesta = (idEncuesta: number) => {
    setIdEncuesta(idEncuesta);
    setRenderList(false);
  }

  const handleAtrasDetalle = () => {
    setIdEncuesta(-1);
    setRenderList(true);
  }

  if(isLoading)
    return <Loader loadingText='Cargando Encuestas...' />

  return (
    <div>
      {renderList
        ? <ListEncuestas listadoEncuestas={listadoEncuestas} selectEncuestaAction={(idEncuesta:number) => handleSelectEncuesta(idEncuesta)} />
        : <DetalleEncuesta onBack={handleAtrasDetalle} idEncuesta={idEncuesta} />}
    </div>
  )
}