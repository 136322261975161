import React, { useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, TextField, Typography } from '@mui/material'
import useAccessCode from '../hooks/useAccessCode';
import { CustomModal } from '../components/Modal';
import { Home } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const AnularAcceso = () => {

    const navigate = useNavigate()

    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const {deleteAccessCode} = useAccessCode()

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
        navigate('/home')
    };

    const handleSubmit = async (e:any) => {
        e.preventDefault()
        const { codigo } = e.target.elements
        let code = await deleteAccessCode(codigo.value)
        if (code && code.success) {
            setModalMessage('Acceso anulado')
        }
        else if (code) {
            setModalMessage(code.message)
        }
        else {
            setModalMessage('Error de sistema al anular el codigo de acceso')
        }
        handleOpen()
    }

    return (
        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>

            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Anular Acceso
            </Typography>

            <Box component="form" display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={500} onSubmit={handleSubmit} >

                <TextField fullWidth required label="Codigo de Acceso" name='codigo' variant="outlined" sx={{marginY:2}} />

                <Button type='submit' color='secondary' size='large' variant="contained" endIcon={<SendIcon/>} sx={{marginY:2 , width:'50%'}} > OK </Button>

            </Box>

            <CustomModal
                openState={openModal}
                onCloseHandler={handleClose}
            >
                <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                    {modalMessage}
                </Typography>

                <Button color='secondary' variant="outlined" endIcon={<Home/>} sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    Inicio
                </Button>

            </CustomModal>
            

        </Box>
    )
}
