import { login, setLoading, setError, logout, setUserData } from "../redux/authSlice";
import lsApi from "../api/lsApi";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { LoginResponse, IUser } from '../models/auth';
import { getUserID } from "../utils/onesignal";
import { esCUI } from "../utils/numbers";

export default function useLogin() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const userData = useAppSelector((state) => state.auth.userData)
    

    const loginHandler = async (userName:string, password:string) => {

        dispatch(setLoading(true))

        try {
            const { data } = await lsApi.post<LoginResponse>("/api/Core/login", { userName, password })
            if (data.success) {
                localStorage.setItem("token", data.access_token)
                await validateToken()
            } 
            else {
                dispatch(setError("Invalid Credentials"))
                alert("Credenciales Invalidas")
            }
        } 
        catch (error) {
            dispatch(setError("Login Failed"))
            alert("Error de sistema al iniciar sesion")
        }

        dispatch(setLoading(false))
    }

    const logoutHandler = () => {

        dispatch(logout())
        dispatch(setUserData({} as IUser))
        localStorage.removeItem("token")
        navigate("/login")

    }

    const validateToken = async () => {

        const token = localStorage.getItem("token")

        if (!token) {
            return 
        }

        try {
            const { data,status } = await lsApi.get<IUser>("/api/ControlAcceso/DatosUsuario")
            if (status === 200) {
                dispatch(setUserData(data))
                dispatch(login({
                    access_token: token,
                    message: "Login Success",
                    success: true
                }))
            }
            else{
                dispatch(logout())
                localStorage.removeItem("token")
                navigate("/login")
            }
        }
        catch (error) {
            dispatch(setError("Invalid Token"))
        }

        try {
            let userID = await getUserID()
            const { data,status } = await lsApi.put("/api/ControlAcceso/RegistrarDispositivo",{
                uuid: "",
                model: "",
                oS_Name: "",
                key_Device: userID,
            })
            if (status === 200 && data.success) {
                console.log("Dispositivo registrado")   
            }
            else{
                console.log("Error al registrar dispositivo")
            }
        }
        catch (error) {
            dispatch(setError("Invalid Token"))
        }

    }

    const updateUserData = async (nombre:string,email:string,nit:string,longitud:string,latitud:string,telefono:string,nombreF:string,direccionF:string) => {

        const { empresa, agencia, casa, usuario } = userData!
        try {
            const { data } = await lsApi.put("/api/ControlAcceso/ActualizarDatosUsuario", {
                empresa,
                agencia,
                id_casa : casa,
                nombre,
                correo: email,
                nit,
                longitud,
                latitud,
                usuario : usuario,
                telefono,
                nombre_fiscal: nombreF,
                direccion_fiscal: direccionF,
            })
            if (data.success) {
                dispatch(setUserData({
                    ...userData!,
                    nombre,
                    email,
                    nit,
                    longitud: Number(longitud),
                    latitud: Number(latitud),
                    telefono,
                    nombre_fiscal: nombreF,
                    direccion_fiscal: direccionF,
                }))
                return true
            } 
            else {
                return false
            }
        } 
        catch (error) {
            console.log(error)
            return false
        }

    }


    const verificarNit = async (nit:string,nombreF:string) => {

        // Verificar si es CF o C/F o si es un CUI valido
        if (nit==="CF" || nit==="C/F"|| esCUI(nit)) {
            return nombreF
        }

        try {
            return await fetch("https://diceros.ls-sys.com/ws/FEL-Condominios/getInfoNIT.php?nit="+nit)
            .then (response => response.json())
            .then (data => {
                if (data.name) {
                    return data.name
                } 
                else {
                    return false
                }
            })
        }
        catch (error) {
            return false
        }
    }


    return { loginHandler,logoutHandler, updateUserData, validateToken, verificarNit }

}

