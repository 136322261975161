export function formatearNumero(numero: Number) {
    let numeroFormateado = Number(numero).toFixed(2);
    let partes = numeroFormateado.split(".");
  
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    let signo = "";
    if (partes[0][0] === "-") {
      signo = "-";
      partes[0] = partes[0].substr(1);
    }
  
    return signo + partes.join(".");
}

export function esCUI (cui:string){
  //Verificar que es un string de 12 o 13 caracteres y que todos son numeros, usa regex
  if (!/^\d{12,13}$/.test(cui)) {
    return false
  }
  return true
}