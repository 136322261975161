import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginResponse, IUser } from '../models/auth';

interface LoginState {
    token: string | undefined
    loading: boolean
    error: string | undefined
    isAuthenticated: boolean
    userData: IUser | undefined
}

const initialState: LoginState = {
    token: undefined,
    loading: false,
    error: undefined,
    isAuthenticated: false,
    userData: undefined
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action :  PayloadAction<LoginResponse> ) => {
            state.token = action.payload.access_token
            state.isAuthenticated = true
        },
        logout: (state) => {
            state.token = undefined
            state.isAuthenticated = false
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setError: (state, action : PayloadAction<string>) => {
            state.error = action.payload
        },
        setUserData: (state, action : PayloadAction<IUser>) => {
            state.userData = action.payload
        }
    },
})

export const { login, logout, setLoading, setError, setUserData } = authSlice.actions

export default authSlice.reducer