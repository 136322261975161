import React from 'react';
import './Confirm.css';

export interface IConfirmProps {
  message: string;
  okAction: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
  cancelAction?: () => void;
  okText?: string;
}

export const Confirm = ({message, okAction, cancelAction, show, setShow, okText = 'Aceptar'}: IConfirmProps) => {
  return (
    <div className={`Confirm ${show ? 'active' : 'inactive'}`}>
      <div className="Confirm__Content">
        <div className='Confirm__Header'>
          <div className='Header__Title'>Confirmar</div>
        </div>
        <div className='Confirm__Body'>
          <div className='Body__Message'>{message}</div>
        </div>
        <div className='Confirm__Buttons'>
          <button className='Button Cancel' onClick={
            cancelAction ? cancelAction : () => setShow(false)
          }>
            Cancelar
          </button>
          <button className='Button Ok' onClick={() => {
            okAction();
            setShow(false);
          }}>
            {okText}
          </button>
        </div>
      </div>
    </div>
  )
};