import { useEffect, useState } from "react"


export default function useLocation() {

    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)
    const [urlLocation, setUrlLocation] = useState("")

    useEffect(() => {
        if ("geolocation" in navigator) {
            getLocation();
        } else {
            alert("GPS no disponible")
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            getLocation()
        }, 60000)

        return () => clearInterval(interval)
    }, [])

    const getLocation = () => {

        navigator.geolocation.getCurrentPosition(function(position) {
            //setUrlLocation(`https://maps.google.com/?q=${position.coords.latitude},${position.coords.longitude}`)
            setUrlLocation(`https://www.waze.com/ul?ll=${position.coords.latitude}%2C${position.coords.longitude}&navigate=yes&zoom=17`)
            setLatitude(position.coords.latitude)
            setLongitude(position.coords.longitude)
        }, function(error) {
            console.log(error)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        })

    }

    return { latitude, longitude, urlLocation }

}