import React from 'react'
import { TableCell as MuiTableCell } from '@mui/material'


export const TableFooter = ( {children,sx}: any ) => {

  return (
    <MuiTableCell sx={{fontSize: 16, color: "#ffffff", background:"#666666", paddingY:"0.2em", ...sx }} >
        {children}
    </MuiTableCell>
  )
}
