import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { createTheme, ThemeProvider } from '@mui/material';
import { Apps } from './pages/Apps';
import ProtectedRoute from './components/ProtectedRoute';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { CrearAcceso } from './pages/CrearAcceso';
import { AnularAcceso } from './pages/AnularAcceso';
import { IngresoGarita } from './pages/IngresoGarita';
import { Perfil } from './pages/Perfil';
import { HistorialAcceso } from './pages/HistorialAcceso';
import { EgresoGarita } from './pages/EgresoGarita';
import { EstadoCuenta } from './pages/EstadoCuenta';
import { PagosRecibidos } from './pages/PagosRecibidos';
import { FotosPruebas } from './pages/FotosPruebas';
import { Reservas } from './pages/Reservas/Reservas';
import { LecturaAgua } from './pages/LecturaAgua';
import runOneSignal from './utils/onesignal';
import { HistoricoLecturaAgua } from './pages/HistoricoLecturaAgua';
import { ControlInmuebles } from './pages/ControlInmuebles';
import { Publicaciones } from './pages/Publicaciones';
import { Encuestas } from './pages/Encuestas';
import { Incidentes } from './pages/Incidentes';

function App() {

  useEffect(() => {
    runOneSignal()
  })

  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/login" replace />}
        />
        <Route path="/login" element={<Login/>}/>

        <Route path="/home" element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }>
          <Route path="" element={<Apps/>} />
          <Route path="crear-acceso" element={<CrearAcceso/>} />
          <Route path="anular-acceso" element={<AnularAcceso/>} />
          <Route path="ingreso-garita" element={<IngresoGarita/>} />
          <Route path="egreso-garita" element={<EgresoGarita/>} />
          <Route path="perfil-usuario" element={<Perfil/>} />
          <Route path="historial-accesos" element={<HistorialAcceso/>} />
          <Route path="estado-cuenta" element={<EstadoCuenta/>} />
          <Route path="pagos-recibidos" element={<PagosRecibidos/>} />
          <Route path="fotos-pruebas" element={<FotosPruebas/>} />
          <Route path="reservas" element={<Reservas/>} />
          <Route path="lectura-agua" element={<LecturaAgua/>} />
          <Route path="historico-agua" element={<HistoricoLecturaAgua/>} />
          <Route path="control-inmuebles" element={<ControlInmuebles/>} />
          <Route path="publicaciones" element={<Publicaciones/>} />
          <Route path="encuestas" element={ <Encuestas /> }/>
          <Route path="crear-incidente" element={ <Incidentes /> }/>
          

          <Route path="*" element={<NotFound/>} />
        </Route>

        <Route path="*" element={<NotFound/>} />

      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'Oswald',
      'Source Sans Pro',
      'sans-serif', 
    ].join(','),
  },

  palette: {
    primary: {
      main: '#013467',
    },
    secondary: {
      main: '#FFCC00',
    },
  },

});

export default App;
