import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { getTodayDate } from '../../utils/date'
import FullCallendar from '@fullcalendar/react'
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Loader } from '../../components/Loader';
import { NewEventForm } from './NewEventForm';
import { IEventoCalendario, IAmenidad } from '../../models/reservas';
import { Alert } from '../../components/Alert';

interface IDisponibilidadProps {
  eventos: IEventoCalendario[];
  loadingEventos: boolean;
  setEventos: (eventos:IEventoCalendario[] | ((prev: IEventoCalendario[]) => IEventoCalendario[])) => void;
  nextStep?: () => void;
  nextText?: string;
  prevStep?: () => void;
  prevText?: string;
  reservaAction?: () => void;
  amenidad?: number|string;
  amenidadesList?: IAmenidad[];
}

export const Disponibilidad = ({
  nextStep, loadingEventos, setEventos, eventos,
  nextText, prevStep, prevText, reservaAction,
  amenidad, amenidadesList
}:IDisponibilidadProps) => {
  const calendar = useRef<any>();
  const [openNewEventForm, setOpenNewEventForm] = useState(false);
  const [dateStr, setDateStr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertOkText, setAlertOkText] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [disabled, setDisabled] = useState(!isNaN(Number([...eventos][eventos.length - 1]?.id ?? -1)));

  useEffect(() => {
    setDisabled(!isNaN(Number([...eventos][eventos.length - 1]?.id ?? -1)));
  }, [eventos]);

const appAlert = (message:string, okText:string = '') => {
  setShowAlert(true);
  setAlertOkText(okText);
  setAlertMessage(message);
}

  const handlePrev = () => {
    const calendarAPI = calendar.current.getApi();
    calendarAPI.prev();
  };

  const handleNext = () => {
    const calendarAPI = calendar.current.getApi();
    calendarAPI.next();
  };

  const changeCallendarView = (view:string, date:string) => {
    const calendarAPI = calendar.current.getApi();
    calendarAPI.changeView(view, date);
  };

  const handleDateClick = (arg:any) => {
    const today = getTodayDate();
    if(arg?.dateStr <= today) return appAlert("Solo seleccione días posteriores a hoy!");

    if(arg?.view?.type !== 'timeGridDay')
      changeCallendarView("timeGridDay", arg?.dateStr);
    else {
      setOpenNewEventForm(true);
      setDateStr(arg?.dateStr);
    }
  }
  return (
    <>

      <NewEventForm
        activeForm={openNewEventForm}
        setActiveForm={setOpenNewEventForm}
        setEvents={setEventos}
        dateStr={dateStr}
        infoAmenidad={amenidadesList?.find(ameni => ameni.servicio === Number(amenidad))}
        alertAction={(msg:string) => appAlert(msg)}
      />

      <Alert
        message={alertMessage}
        okText={alertOkText}
        show={showAlert}
        setShow={(show:boolean) => setShowAlert(show)}
        type='warning'
      />

      <Box>
        Verifique la disponibilidad:
      </Box>
      <Box>
        <Typography variant="body2" component="div" gutterBottom marginY='1em' textAlign={'left'}>
          Amenidad: {amenidadesList?.find(ameni => ameni.servicio === Number(amenidad))?.nombre}
          <br/>
          Horario: {amenidadesList?.find(ameni => ameni.servicio === Number(amenidad))?.inicio_Amenidad} hrs - {amenidadesList?.find(ameni => ameni.servicio === Number(amenidad))?.fin_Amenidad} hrs
          <br/>
          Tiempo máximo: {amenidadesList?.find(ameni => ameni.servicio === Number(amenidad))?.tiempo_Maximo} hrs
        </Typography>
      </Box>
      <Box
        display={'flex'}
        alignItems={'stretch'}
        paddingBottom={'10rem'}
        justifyContent={'center'}
        flexDirection={'column'}
        width={'90%'}
      >
        <Box
          marginY={'1rem'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Box>
            <Button color='primary' variant='contained' onClick={handlePrev}><ArrowLeft/></Button>
            <Button color='primary' variant='contained' onClick={handleNext}><ArrowRight/></Button>
          </Box>
          <Button color='primary' variant='contained' onClick={() => {
            const calendarAPI = calendar.current.getApi();
            calendarAPI.today();
          }}>Hoy</Button>
        </Box>
        {
          loadingEventos
          ? <Loader
            loadingText={'Cargando eventos...'}
          />
          : <FullCallendar
              locale={esLocale}
              ref={calendar}
              expandRows={true}
              plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
              initialView="dayGridMonth"
              dateClick={handleDateClick}
              headerToolbar={{
                left: 'title',
                center: 'dayGridMonth,timeGridDay',
                right: '',
              }}
              height={500}
              events={[...eventos]}
              views={{
                dayGrid: {
                  titleFormat: {
                    year: '2-digit',
                    month: 'long'
                  }
                }
              }}
            />
        }
      </Box>

      <Box
          display={'flex'}
          justifyContent={prevStep && nextStep ? 'space-between' : 'center'}
          position={'fixed'}
          bottom={0}
          left={0}
          width={'calc(100% - 2rem)'}
          borderTop={'1px solid #AAA'}
          paddingY={'1rem'}
          bgcolor={'white'}
          zIndex={30}
          paddingX={'1rem'}
        >
          {prevStep &&
          <Button
            color='primary'
            variant="outlined"
            sx={{width:'40%'}}
            onClick={prevStep}
          >
              {prevText}
          </Button>}
          {nextStep &&
          <Button
            color='primary'
            variant="outlined"
            sx={{width:'40%'}}
            onClick={nextStep}
            disabled={disabled}
          >
              {nextText}
          </Button>}
          {reservaAction &&
          <Button color='primary' variant="contained" sx={{width:'40%', marginX: '0.5rem'}} onClick={reservaAction}>
              Reservar
          </Button>}
        </Box>
    </>
  )
}