import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { IAmenidad } from '../../models/reservas';

interface ISeleccionAmenidadProps {
  setAmenidad: (amenidad:number) => void;
  amenidad: number;
  amenidadesList: Array<IAmenidad>;
  previousStep?: () => void;
  nextStep?: () => void;
  previousText?: string;
  nextText?: string;
  reservaAction?: () => void;
}

export const SeleccionAmenidad = ({
  setAmenidad, amenidad, amenidadesList,
  previousStep, nextStep, previousText, nextText,
  reservaAction
}:ISeleccionAmenidadProps) => {
  const [disabled, setDisabled] = React.useState(amenidad === -1);

  return (
      <Box width={'90%'}>
        <Typography variant="body2" component="div" gutterBottom marginY='1em' textAlign={'left'}>
          Seleccione la amenidad que quiere reservar:
        </Typography>

        <TextField fullWidth required select name='amenidad' label="Amenidad" variant="outlined"
          sx={{marginY:2, width: '100%'}}
          SelectProps={{native: true}} defaultValue={amenidad}
          onChange={(el) => {
            setDisabled(Number(el.target.value) === -1)
            setAmenidad(Number(el.target.value))
          }}
        >
          {
            !amenidadesList.some((amenidad:IAmenidad) => Number(amenidad.servicio) === -1) &&
            <option key="no-seleccionado" value={-1}>
              -- seleccione una amenidad --
            </option>
          }
          {amenidadesList.map((option:IAmenidad) => (
            <option key={option.servicio} value={option.servicio}>
              {option.nombre}
            </option>
          ))}
        </TextField>
        <Box
          display={disabled ? 'none' : 'flex'}
          justifyContent={'flex-end'}
          flexDirection={'column'}
          width={'100%'}
          marginY={'1em'}
          boxShadow={'0px 0px 20px 4px rgba(140,140,140,0.4)'}
          borderRadius={'0.5rem'}
        >
          <Typography variant="body2"
            fontSize={'1rem'}
            component="div"
            gutterBottom marginY='1em' marginX={'1em'} marginTop={'2rem'} textAlign={'left'}
          >
            Horario: {amenidadesList.find(ameni => ameni.servicio === Number(amenidad))?.inicio_Amenidad}hrs - {amenidadesList.find(ameni => ameni.servicio === Number(amenidad))?.fin_Amenidad}hrs
          </Typography>
          <Typography
            variant="body2"
            component="div"
            gutterBottom marginY='1em' marginX={'1em'} textAlign={'left'}
            fontSize={'1rem'}
          >
            Precio: {amenidadesList.find(ameni => ameni.servicio === Number(amenidad))?.valor?.toFixed(2)}
          </Typography>
          <Typography variant="body2" component="div" gutterBottom marginY='1em'
            marginX={'1em'} marginBottom={'2rem'} textAlign={'left'}
            fontSize={'1rem'}
          >
            Tiempo Máximo: {amenidadesList.find(ameni => ameni.servicio === Number(amenidad))?.tiempo_Maximo?.toFixed(2)} hrs
          </Typography>
        </Box>
        <Box
          display={'flex'}
          justifyContent={previousStep && nextStep ? 'space-between' : 'center'}
          position={'fixed'}
          bottom={0}
          left={0}
          width={'calc(100% - 2rem)'}
          borderTop={'1px solid #AAA'}
          paddingY={'1rem'}
          bgcolor={'white'}
          zIndex={30}
          paddingX={'1rem'}
        >
          {previousStep &&
          <Button color='primary' variant="outlined" sx={{width:'40%', marginX: '0.5rem'}} onClick={previousStep}>
              {previousText}
          </Button>}
          {nextStep &&
          <Button color='primary' disabled={disabled} variant="outlined" sx={{width:'40%', marginX: '0.5rem'}} onClick={nextStep}>
              {nextText}
          </Button>}
          {reservaAction &&
          <Button color='primary' variant="contained" sx={{width:'40%', marginX: '0.5rem'}} onClick={reservaAction}>
              Reservar
          </Button>}
        </Box>
      </Box>
  )
}