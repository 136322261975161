import React from 'react';
import { IEventoCalendario, IAmenidad } from '../../../models/reservas';
import './NewEventForm.css';

interface NewEventFormProps {
  activeForm: boolean;
  setActiveForm: (activeForm: boolean) => void;
  setEvents: (eventos:IEventoCalendario[] | ((prev: IEventoCalendario[]) => IEventoCalendario[])) => void;
  dateStr: string;
  infoAmenidad: IAmenidad | undefined;
  alertAction?: (msg:string) => void;
}

const getEndTime = (date:string, hours:number) => {
  const [mainDate, time] = date.split('T');
  const timeHour = Number(time.split(':')[0])
  const endTime = timeHour + hours;
  return `${mainDate}T${endTime.toString().padStart(2, '0')}:00:00`
};

export const NewEventForm = ({ activeForm, setActiveForm, setEvents, dateStr, infoAmenidad, alertAction = (m) => alert(m) }: NewEventFormProps) => {
  const [eventTitle, setEventTitle] = React.useState<string>('');
  const [eventTime, setEventTime] = React.useState<string>('');
  const { inicio_Amenidad, fin_Amenidad, tiempo_Maximo } = infoAmenidad ?? { inicio_Amenidad: 0, fin_Amenidad: 24, tiempo_Maximo: 24 };

  const handleCreateEvent = (evTitle:string, evTime:number) => {
    if(!(evTitle.length || evTime))
      return alertAction('Por favor, ingrese un titulo y un tiempo en horas!');
    const endTime = getEndTime(dateStr, evTime);
    const hrStart = Number(dateStr.split('T')[1].split(':')[0]);
    const hrEnd = Number(endTime.split('T')[1].split(':')[0]);

    if(hrStart < inicio_Amenidad)
      return alertAction(`Las ${hrStart}hrs queda fuera del horario de la amenidad. (de ${inicio_Amenidad}hrs a ${fin_Amenidad}hrs)`);

    if(hrEnd > fin_Amenidad)
    return alertAction(`Las ${hrEnd}hrs queda fuera del horario de la amenidad. (de ${inicio_Amenidad}hrs a ${fin_Amenidad}hrs)`);

    if((hrEnd - hrStart) > tiempo_Maximo)
      return alertAction(`El tiempo máximo de la reserva debe ser ${tiempo_Maximo} horas`);

    const newEvent:IEventoCalendario = {
      id: evTitle ?? "no-event",
      title: evTitle ?? "No event",
      start: dateStr,
      end: endTime ?? ""
    }
    setEvents((prev:IEventoCalendario[]) => [...prev, newEvent]);
    setEventTitle('');
    setEventTime('');
    setActiveForm(false);
  };

  return (
    <div className={`bg-overlay ${activeForm ? 'active' : 'inactive'}`}>
      <div className='event-form'>
        <div className='form-bar'>
          <button onClick={() => setActiveForm(false)}>&times;</button>
        </div>
        <div className='form-title'>
          <h4>Crear Evento</h4>
        </div>
        <div className='form-body'>
          <div className='form-field'>
            <label htmlFor='title'>Titulo</label>
            <input
              type='text' name='title' id='title'
              onChange={(e) => setEventTitle(e.target.value)}
              value={eventTitle}
            />
          </div>
          <div className='form-field'>
            <label htmlFor='title'>Tiempo (hrs)</label>
            <input
              type='number' name='horas' id='horas'
              onChange={(e) => setEventTime(e.target.value)}
              value={eventTime}
            />
          </div>
        </div>
        <div className='form-submit'>
          <button onClick={() => handleCreateEvent(eventTitle, Number(eventTime))}>Listo</button>
        </div>
      </div>
    </div>
  );
}