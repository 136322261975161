import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILecturaAgua } from '../models/lecturas';

interface ReadingsState {
    readingsData: ILecturaAgua[]
}

const initialState: ReadingsState = {
    readingsData: []
}

export const readingsSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setReadingsData: (state, action :  PayloadAction<ILecturaAgua[]> ) => {
            state.readingsData = action.payload
        },
        updateItem : (state, action: PayloadAction<ILecturaAgua>) => {
            const index = state.readingsData.findIndex( item => item.num_Casa === action.payload.num_Casa)
            state.readingsData[index] = action.payload
        },
        getByEstado: (state, action: PayloadAction<string>) => {
            state.readingsData = state.readingsData.filter( item => item.estado === action.payload)
        }

    },
})

export const { setReadingsData, updateItem, getByEstado } = readingsSlice.actions

export default readingsSlice.reducer