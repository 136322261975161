import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DataCard } from '../components/DataCard';
import { usePublicaciones } from '../hooks/usePublicaciones';
import { IPublicacion } from '../models/publicaciones';

export const Publicaciones = () => {
  const { getPublicaciones } = usePublicaciones();
  const [publicaciones, setPublicaciones] = useState<IPublicacion[]>([]);

  useEffect(() => {
    getPublicaciones()
    .then(data => setPublicaciones(data))
    .catch(err => console.error(err));
  }, []);

  return (
    <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>
      <Typography variant="h4" component="div" gutterBottom marginY='1em'>
        Notificaciones
      </Typography>
      <Box textAlign={'left'}>
        {
          publicaciones.length > 0
            ? publicaciones.map(el => <DataCard key={el.correlativo} data={{
              titulo: el.titulo,
              fecha: el.fecha,
              descripcion: el.descripcion,
              link_src: el.doc_Fuente,
              link_text: el.doc_Texto
            }}/>)
            : <div> No hay notificaciones... </div>
        }
      </Box>
    </Box>
  )
}