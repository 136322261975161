import React from 'react'
import {Navigate, useLocation} from "react-router-dom"
import { useAppSelector } from '../redux/hooks';

const ProtectedRoute = ({children}:any) => {

    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    
    let location = useLocation();

    if(!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location}} replace />
    }

    return children

};

export default ProtectedRoute;