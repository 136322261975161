import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowBack, Logout } from '@mui/icons-material'
import useLogin from '../hooks/useLogin'
import { Notificaciones } from './Notificaciones'

const pages = [
    { name: 'Home', href: '/home'},
]

export const NavBar = () => {
    
    const navigate = useNavigate()
    const location = useLocation()
    const { logoutHandler } = useLogin()

    return (
    <AppBar position="static">
        <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Box sx={{
                display: { xs: 'none', md: 'flex' },
                width: 60,
                height: 60,
                marginY: 1,
            }}>
                <img src='assets\LogicSoft.png' alt='LogicSoft Logo'/>
            </Box>


            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
                <Button
                    key={page.name}
                    onClick={() => navigate(page.href)}
                    sx={{ mx: 2, color: 'white', display: 'block', letterSpacing: '.2rem' }}
                    className='scale-up-hover'
                >
                    {page.name}
                </Button>
            ))}
            </Box>

            {
                location.pathname === '/home'
                ?   <Box sx={{
                        display: 'flex',
                        width: 60,
                        height: 60,
                        marginY: 1,
                    }}>
                        <Notificaciones action={() => navigate('/home/publicaciones')} /> 
                    </Box>
                : null
            }

            <Box sx={{
                display: { xs: 'none', md: 'flex' },
                width: 60,
                height: 60,
                marginY: 1,
            }}>
                <IconButton
                    size="large"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => logoutHandler()}
                    color="inherit"
                >
                    <Logout />
                </IconButton>
            </Box>

            {
            location.pathname !== '/home'
                ?   <Box sx={{
                        display: { xs: 'flex', md: 'none' },
                        width: 60,
                        height: 60,
                        marginY: 1,
                        justifyContent: 'center',
                    }}>
                        <IconButton
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => navigate('/home')}
                            color="inherit"
                        >
                            <ArrowBack fontSize='large'/>
                        </IconButton>
                    </Box>
                : null
            }

            <Box sx={{
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                width: 60,
                height: 60,
                marginY: 1,
                justifyContent: 'center',
            }}>
                <img src='/assets/LogicSoft.png' alt='LogicSoft Logo'/>
            </Box>

            <Box sx={{
                display: { xs: 'flex', md: 'none' },
                width: 60,
                height: 60,
                marginY: 1,
                justifyContent: 'center',
            }}>
                <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => logoutHandler()}
                    color="inherit"
                >
                    <Logout fontSize='large'/>
                </IconButton>
            </Box>


        </Toolbar>
        </Container>
    </AppBar>
    )
}
