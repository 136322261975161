import React, { useState } from 'react'
import { Button, Card, CardContent, Chip, TextField, Typography } from '@mui/material';
import { ILecturaAgua } from '../models/lecturas'
import { useAppDispatch } from '../redux/hooks';
import { updateItem } from '../redux/readSlice';
import { useLecturas } from '../hooks/useLecturas';
import { s } from '@fullcalendar/core/internal-common';

interface LecturaAguaItemProps {
    lectura: ILecturaAgua
}

export const LecturaAguaItem = (props: LecturaAguaItemProps) => {

    const { lectura } = props

    const { sendLectura } = useLecturas()

    const { lect_Actual, lect_Anterior, limite_M3, num_Casa } = lectura
    const [lecturaActual, setLecturaActual] = useState(lect_Actual)
    const [consumo, setConsumo] = useState(-1)

    const dispatch = useAppDispatch()

    const handleGuardar = async () => {

        console.log(lecturaActual+' '+lect_Anterior+' '+limite_M3)

        const consumoTemp = lecturaActual - lect_Anterior

        if(consumoTemp < 0){
            alert('La lectura actual no puede ser menor a la anterior')
            return
        }
        else if(consumoTemp > limite_M3){
            if(!window.confirm(`El consumo es de ${consumoTemp.toFixed(2)} m3, y excede el limite de ${limite_M3} m3. ¿Esta seguro de guardar el dato?`)){
                return
            }
        }
        else if(consumoTemp==0){
            if(!window.confirm('La lectura actual es igual a la anterior. ¿Esta seguro de guardar el dato?')){
                return
            }
        }
        setConsumo(consumoTemp)

        const req = await sendLectura({
            ...lectura,
            lect_Actual: lecturaActual
        })

        if (req == 'offline') {
            dispatch(updateItem({
                ...lectura,
                lect_Actual: lecturaActual,
                estado: 'Guardado Offline'
            }))
        }
        else if (req && req.success){
            dispatch(updateItem({
                ...lectura,
                lect_Actual: lecturaActual,
                estado: 'Guardado'
            }))
        }
        else{
            alert('No se pudo guardar la lectura')
        }
    }

    return (
        <Card  
            sx={{
                width: { xs: '70%', md: '30%' },
                margin: '1em',
                padding: '2em',
            }}
        >
            <CardContent>
                
                {lectura.estado === undefined && <Chip label="Sin guardar" color="error" size="small" sx={{alignSelf:'end',minWidth:'30%'}}/>}
                {lectura.estado === 'Guardado' && <Chip label="Guardado" color="success" size="small" sx={{alignSelf:'end',minWidth:'30%'}}/>}
                {lectura.estado === 'Guardado Offline' && <Chip label="Guardado Offline" color="warning" size="small" sx={{alignSelf:'end',minWidth:'30%'}}/>}

                <Typography sx={{ my: 1.5 }} color="text.secondary">
                    Casa {num_Casa}
                </Typography>

                <Typography variant="h6" component="div">
                    Lectura Anterior: {lect_Anterior}
                </Typography>

                <TextField fullWidth required type='number' defaultValue={lecturaActual} label="Lectura Actual" variant="outlined" 
                    sx={{marginY:2}} 
                    onChange={(e) => setLecturaActual(parseFloat(e.target.value))}
                    InputProps={{
                        inputProps: {
                            min: 0,
                        }
                    }}
                />

                <Button color='secondary' variant="contained" sx={{marginY:2 , width:'40%'}} onClick={handleGuardar}>
                    Guardar
                </Button>

                {consumo >= 0 &&
                    <Typography variant="h6" sx={{ mt: 1.5 }} component="div">
                        Consumo: {consumo.toFixed(2)} m3
                    </Typography>
                }

            </CardContent>
            
        </Card>
    )
}
