import lsApi from "../api/lsApi";
import { useAppSelector } from '../redux/hooks';
import { IReceivedPayments } from '../models/payments';

export function usePagosRecibidos() {
  const userData = useAppSelector((state) => state.auth.userData);
  const { nombre, usuario } = userData!;
  const propietario = nombre;
  const casa = usuario.slice(2);
  const newDate = new Date();
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const errFecha = `${newDate.getFullYear()}-${month}-00`;

  const getPagosRecibidos = async () => {
    try {
      const { status, data } = await lsApi.get<IReceivedPayments[]>(`/api/ControlAcceso/PagosDelMes?INANIO=${newDate.getFullYear()}&VACASA=${casa}`);
      if (status === 200) {
        return data;
      } else {
        return [
          {
            id: status.toString(),
            num_Boleta: `status ${status}`,
            fecha: errFecha,
            valor: status
          }
        ];
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return {
    getPagosRecibidos,
    propietario,
    casa
  };
}