import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

interface CustomModalProps {
    openState: boolean
    onCloseHandler: () => void
    children: any
}

export const CustomModal = ( props: CustomModalProps ) => {

    const {openState, onCloseHandler} = props

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: '75%', sm: '50%'},
        bgcolor: 'white',
        border: '2px solid',
        borderRadius: 4,
        boxShadow: 10,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4,
        maxHeight: '90%',
        overflowY: 'auto'
    }

    return (
        <Modal
            open={openState}
            onClose={onCloseHandler}
        >
            <Box sx={modalStyle}>

                {props.children}

            </Box>



        </Modal>
    )
}
