import React from 'react'
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material'
import { useState, useEffect } from 'react';
import { Home} from '@mui/icons-material';
import useAccessCode from '../hooks/useAccessCode';
import { CustomModal } from '../components/Modal';
import { IMotive } from '../models/codes';
import { useNavigate } from 'react-router-dom';
import { ShareCode } from '../components/ShareCode';
import { getTodayDate } from '../utils/date';

export const CrearAcceso = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    
    const [motivesList , setMotivesList] = useState<IMotive[]>([])
    
    const [groupAccess, setGroupAccess] = useState(false)
    const [fechaAbierta, setFechaAbierta] = useState(false)

    const [openQRModal, setOpenQRModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    
    const [accessCode , setAccessCode] = useState('')
    
    const { createAccessCode, getMotives } = useAccessCode()

    useEffect(() => {
        (async () => {
            setLoading(true)
            const motives = await getMotives()
            if (motives) {
                setMotivesList(motives)
                setLoading(false)
            }
        })()
    }, [])

    const handleOpen = () => setOpenQRModal(true);
    const handleClose = () => {
        setOpenQRModal(false)
        setAccessCode('')
        navigate('/home')
    }

    const handleSubmit = async ( e : any ) => {
        e.preventDefault()
        const { nombre, motivo, fecha, limite, nota } = e.target.elements
        
        let code = await createAccessCode(nombre.value, motivo.value, fecha.value, fechaAbierta?1:0, limite?limite.value:1, nota.value)
        if (code && code.success) {
            setAccessCode(code.codigo_Acceso)
        }
        if (code) {
            setModalMessage(code.message)
        }
        else {
            setModalMessage('Error al crear el codigo de acceso')
        }
        handleOpen()
    }

    if (loading) {
        return (
            <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>
                <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                    Cargando...
                </Typography>
            </Box>
        )
    }

    return (
        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>

            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Crear Acceso
            </Typography>

            <Box component="form" display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={500} onSubmit={handleSubmit} >

                {!groupAccess && <TextField fullWidth required name='nombre' label="Nombre Visita" variant="outlined" sx={{marginY:2}} /> }

                <TextField fullWidth required select name='motivo' label="Motivo de Visita" variant="outlined" sx={{marginY:2}}
                    SelectProps={{native: true}} defaultValue="1"
                >
                    {motivesList.map((option) => (
                        <option key={option.motivo} value={option.motivo}>
                            {option.nombre}
                        </option>
                    ))}

                </TextField>

                <TextField
                    required
                    fullWidth
                    name='fecha'
                    label="Fecha de Visita"
                    defaultValue={getTodayDate().slice(0, 10)}
                    type="date"
                    InputLabelProps={{shrink: true}}
                    sx={{marginY:2}}
                />

                <Box textAlign="center" display="flex" alignItems="center" >

                <FormControlLabel control={
                    <Switch onChange={(e) => setFechaAbierta(e.target.checked)} checked={fechaAbierta} />
                } label="Fecha Abierta"  
                />

                <FormControlLabel control={
                    <Switch onChange={(e) => setGroupAccess(e.target.checked)} checked={groupAccess} />
                } label="Acceso Grupal" 
                />

                </Box>
                
                {groupAccess && <>
                    <TextField fullWidth required name='nombre' label="Nombre del Evento" variant="outlined" sx={{marginY:2}} />
                    <TextField fullWidth required type='number' name='limite' defaultValue='2' label="Cantidad" variant="outlined" sx={{marginY:2}}
                        InputProps={{
                            inputProps: {
                                min: 2,
                                max: 1000
                            }
                        }}
                    />
                </>
                }

                <TextField multiline rows={2} fullWidth label="Nota" name='nota' variant="outlined" sx={{marginY:2}} 
                    InputProps={{
                        inputProps: {
                            maxLength: 150
                        }
                    }}
                />

                <Button type='submit' color='secondary' size='large' variant="contained" endIcon={<SendIcon/>} sx={{marginY:2 , width:'50%'}} > OK </Button>

            </Box>

            <CustomModal
                openState={openQRModal}
                onCloseHandler={handleClose}
            >
                
                <Typography variant="h6">
                    Codigo de Acceso
                </Typography>

                { accessCode !=='' && <ShareCode accessCode={accessCode} message={modalMessage} /> }

                { accessCode === '' && <>

                    <Typography variant="h6" component="div" gutterBottom>
                        {modalMessage}
                    </Typography>

                </>}

                <Button color='secondary' variant="outlined" endIcon={<Home/>}  sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    Inicio
                </Button>

            </CustomModal>
            
        </Box>
    )
}
