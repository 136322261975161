import lsApi from "../api/lsApi";
import { IPublicacion } from '../models/publicaciones';

export function usePublicaciones() {

    const getPublicaciones = async () => {

        try{
            let { data, status } = await lsApi.get<IPublicacion[]>('/api/ControlAcceso/ListadoPublicaciones')
            if (status === 200) {
                return data;
            }
            else{
                return [
                  {
                    correlativo: -1,
                    fecha: 'Error! Status: ' + status,
                    titulo: '',
                    descripcion: ''
                  }
                ]
            }
        }
        catch (error) {
            console.error(error);
            return [
              {
                correlativo: -1,
                fecha: 'Err',
                titulo: '',
                descripcion: ''
              }
            ]
        }
    }

    return {
      getPublicaciones
    }
}