import React, { useEffect, useState } from 'react'
import { Box, Button, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import useAccessCode from '../hooks/useAccessCode';
import { useNavigate } from 'react-router-dom';
import { IHistoryAccessCode } from '../models/codes';
import { CustomModal } from '../components/Modal';
import { Home } from '@mui/icons-material';
import { TableHeaderCell } from '../components/Table/TableHeaderCell';
import { TableCell } from '../components/Table/TableCell';

export const EgresoGarita = () => {

    const navigate = useNavigate()

    const { getHistoryEgress, giveEgress } = useAccessCode()

    const [history, setHistory] = useState<IHistoryAccessCode[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
        navigate('/home')
    };
    

    useEffect(() => {
        (async () => {
            let history = await getHistoryEgress()
            if (history) {
                setHistory(history)
            }
            else{
                setModalMessage('Error al obtener el historial')
                handleOpen()
            }
        })()    

    }, [])

    const handleSelect = async (index : number) => {
        
        const {correlativo, placas, vehiculo, num_Documento} = history[index]
        if(!window.confirm(`¿Desea egresar el vehiculo con placas ${placas}?`)){
            return
        }

        const data =  await giveEgress(correlativo, placas, vehiculo, 'DUMMY_COLOR', num_Documento)
        if(data && data.success){
            setModalMessage('Egreso exitoso')
        }
        else if (data){
            setModalMessage(data.message)
        }
        else{
            setModalMessage('Error de sistema al egresar')
        }
        handleOpen()
    }

    return (
        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>

            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Historial de Accesos
            </Typography>
            <Typography variant="h6" component="div" gutterBottom >
                Toca la fila para egresar
            </Typography>

            <Box display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={800} overflow='auto' >

            <Table stickyHeader>
                <TableHead>
                    <TableRow >
                        <TableHeaderCell>Placa</TableHeaderCell>
                        <TableHeaderCell>Fecha Ingreso</TableHeaderCell>
                        <TableHeaderCell>Vehiculo</TableHeaderCell>
                        <TableHeaderCell>Color</TableHeaderCell>
                        <TableHeaderCell>Documento</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {history.length > 0 && history.map((item, index) => (
                        <TableRow key={index} onClick={()=> handleSelect(index)}>
                            <TableCell>{item.placas}</TableCell>
                            <TableCell>{item.fecha_Ingreso}</TableCell>
                            <TableCell>{item.vehiculo}</TableCell>
                            <TableCell></TableCell>
                            <TableCell>{item.num_Documento}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            </Box>

            
            <CustomModal
                openState={openModal}
                onCloseHandler={handleClose}
            >
                <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                    {modalMessage}
                </Typography>

                <Button color='secondary' variant="outlined" endIcon={<Home/>} sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    Inicio
                </Button>

            </CustomModal>
            

        </Box>
    )
}
