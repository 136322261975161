import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface AppBtnProps {
    name: string
    icon: string
    url: string
}

export const AppBtn = (props : AppBtnProps ) => {

    const { name, icon, url } = props
    const navigate = useNavigate()


    return (
    <Box
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        display="flex"
        flexDirection="column"
        className="clickable"
        onClick={() => navigate(url)}
    >
        <img
            src={icon}
            alt={name}
            width={100}
            height={100}
        />
        
        <Typography variant="h6" component="div" gutterBottom>
            {name}
        </Typography>

        
    </Box>
    )
}
