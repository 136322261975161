import React from 'react'

export const NotFound = () => {
  return (
    <div>
        <h1>404</h1>
        <h2>Pagina no encontrada</h2>
    </div>
  )
}
