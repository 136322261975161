import React from 'react';
import './Loader.css';


interface ILoaderProps {
  loadingText: string;
}

export const Loader = ({ loadingText }: ILoaderProps) => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <div className="loader-text">{ loadingText }</div>
    </div>
  );
}