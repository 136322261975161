import React from 'react';
import { Box, Typography } from '@mui/material';
import { DataCard } from '../../components/DataCard';
import { IListEncuesta } from '../../models/encuestas';

interface ListEncuestasProps {
  listadoEncuestas: IListEncuesta[];
  selectEncuestaAction: (idEncuesta:number) => void;
}

export const ListEncuestas = ({listadoEncuestas, selectEncuestaAction}: ListEncuestasProps) => {

  return (
    <Box textAlign="center" display="flex" alignItems="center" width={'100%'} height={'100vh'} flexDirection='column'>
      <Typography variant="h4" component="div" gutterBottom marginY='1em'>
        Listado de Encuestas
      </Typography>

      <Box textAlign={'left'} width={'100%'}>
        <Typography marginX={'1rem'} variant='body1' component="p" gutterBottom>
          Seleccione la encuesta que desea llenar:
        </Typography>
      </Box>

      <Box textAlign="left" width={'100%'}>
        {
          listadoEncuestas.length > 0 &&
          listadoEncuestas.map(encuesta => (
            <DataCard key={encuesta.formulario}
              onClick={() => selectEncuestaAction(encuesta.formulario)}
              variant='Encuesta'
              data={{
                titulo: encuesta.nombre,
                fecha: encuesta.fecha,
                descripcion: encuesta.descripcion
              }}
            />
          ))
        }
      </Box>

    </Box>
  )
}