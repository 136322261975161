
export const shareQR = (canvas:HTMLCanvasElement, message:string ) => {

    canvas.toBlob(async (blob) => {
        const files = [new File([blob!], 'image.png', { type: blob!.type })]
        const shareData = {
            files,
            title: 'Codigo de Acceso',
            text: `${message}`
        }

        if (navigator.canShare({ files })) {
            try {
                await navigator.share(shareData)
            } 
            catch (err:any) {
                if (err.name !== 'AbortError') {
                    console.error(err.name, err.message)
                }
            }
        } 
        else {
            console.warn('Sharing not supported', shareData)
        }
    })

}

export const shareText = async ( message:string ) => {

    const shareData = {
        title: 'Codigo de Acceso',
        text: `${message}`
    }

    if (navigator.canShare(shareData)) {
        try {
            await navigator.share(shareData)
        } 
        catch (err:any) {
            if (err.name !== 'AbortError') {
                console.error(err.name, err.message)
            }
        }
    } 
    else {
        console.warn('Sharing not supported', shareData)
    }

}


export const shareLocationUrl = (urlLocation:string, canvas:HTMLCanvasElement, message:string) => {

    canvas.toBlob(async (blob) => {
        const files = [new File([blob!], 'image.png', { type: blob!.type })]

        const shareData = {
            title: 'Codigo de Acceso',
            text: `${message}`,
            url: `${urlLocation}`
        }

        if (navigator.canShare({ files })) {
            try {
                await navigator.share(shareData)
            } 
            catch (err:any) {
                if (err.name !== 'AbortError') {
                    console.error(err.name, err.message)
                }
            }
        } 
        else {
            console.warn('Sharing not supported', shareData)
        }
    })
}