import React, {useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, TextField, Typography } from '@mui/material'
import { QrReader } from 'react-qr-reader';
import { CustomModal } from '../components/Modal';
import { Home, Clear } from '@mui/icons-material';
import useAccessCode from '../hooks/useAccessCode';
import { useNavigate } from 'react-router-dom';
import { VerificarAcceso } from '../components/VerificarAcceso';
import { IVerifyCodeResponse } from '../models/codes';

export const IngresoGarita = () => {

    const navigate = useNavigate()

    const [openModal , setOpenModal] = useState(false)
    const [modalMessage , setModalMessage] = useState('')
    const [accessCode, setAccessCode] = useState('');
    const [verifyCodeResponse , setVerifyCodeResponse] = useState<IVerifyCodeResponse>({} as IVerifyCodeResponse)

    const {validateAccessCode} = useAccessCode()


    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
        navigate('/home')
    };

    const handleSubmit = async (e : any) => {
        e.preventDefault()
        const { codigo } = e.target.elements
        let validation = await validateAccessCode(codigo.value)
        if (validation && validation.success) {
            setAccessCode(codigo.value)
            setVerifyCodeResponse(validation)
        }
        else if (validation){
            setModalMessage(validation.message)
        }
        else{
            setModalMessage('Error de sistema al validar el codigo de acceso')
        }
        handleOpen()
        
    }

    const handleQR = async (result : any) => {

        if (result) {

            const regex = /^[A-Z]?[0-9]{4}$/

            if (regex.test(result?.text)) {
                
                let validation = await validateAccessCode(result.text)
                if (validation && validation.success) {
                    setAccessCode(result.text)
                    setVerifyCodeResponse(validation)
                }
                else if (validation){
                    setModalMessage(validation.message)
                }
                else{
                    setModalMessage('Error de sistema al validar el codigo de acceso')
                }
                handleOpen()
            }
        }
    }
    
    return (

        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>
            
            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Ingreso Garita
            </Typography>

            <Box component="form" display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={500} onSubmit={handleSubmit} >

                <TextField fullWidth required name='codigo' label="Codigo de Acceso" variant="outlined" sx={{marginY:2}} autoFocus/>

                <Button type='submit' color='secondary' size='large' variant="contained" endIcon={<SendIcon/>} sx={{marginY:2 , width:'50%'}} > OK </Button>
                
                <Typography variant="h6" component="div" gutterBottom marginY='1em'>
                    O escanea el codigo QR
                </Typography>

                <QrReader
                    onResult={ (result, error) => handleQR(result) }
                    constraints={{ facingMode: 'environment' }}
                    containerStyle={{ 
                        width: '50vh',
                    }}
                    videoContainerStyle={{
                        display: 'flex',
                        width: '80%',
                        margin: 'auto',
                        alignSelf: 'center', 
                    }}
                />

            </Box>

            <CustomModal
                openState={openModal}
                onCloseHandler={handleClose}
            >
                
                { accessCode !== '' && <>
                    <VerificarAcceso setModalMessage={setModalMessage} setAccessCode={setAccessCode} accessCode={accessCode} verifyCodeResponse={verifyCodeResponse} />
                    
                    <Button color='error' variant="contained" endIcon={<Clear/>}  sx={{margin:2 , width:'50%'}} onClick={() => setOpenModal(false)}>
                        Cancelar
                    </Button>
                </>}

                { accessCode === '' && <>
                    <Typography variant="h6" component="div" gutterBottom>
                        {modalMessage}
                    </Typography>
                </>}

                <Button color='secondary' variant="contained" endIcon={<Home/>}  sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    Inicio
                </Button>
                
            </CustomModal>
            
        </Box>

    )
}
