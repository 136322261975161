import React from 'react'
import { TableCell as MuiTableCell } from '@mui/material'


export const TableHeaderCell = ( {children,sx}: any ) => {

  return (
    <MuiTableCell sx={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center', background:"#FFCC00", paddingY:"0.2em", ...sx }}>
        {children}
    </MuiTableCell>
  )
}
