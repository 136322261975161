const mesesDelAnio = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

export const getTodayDate = () => {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
    
    return localISOTime

}

export const convertDate = (date: string) => {
    if (date === '') return '';
    const dateArray = date.split(' ');
    const dateArray2 = dateArray[0].split('-');
    const dateArray3 = dateArray[1].split(':');
    const dateArray4 = dateArray2[1] + '/' + dateArray2[2] + '/' + dateArray2[0] + ' ' + dateArray3[0] + ':' + dateArray3[1];
    return dateArray4;
}

export const getMonthNameByDate = (date: Date): string => {
    let monthNum = date.getMonth();
    return mesesDelAnio[monthNum];
};

export const yyyyMMddToDDmmYYYY = (date: string, delimiter: string = '-'): string => {
    if(date.length === 0) return date;
    let newDate = date.replace(/\//g, '-');
    newDate = newDate.split('T')[0];
    const [yyyy, mm, dd] = newDate.split('-');
    return [dd, mm, yyyy].join(delimiter);
}