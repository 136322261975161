import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { AppBtn } from '../components/AppBtn'
import { Footer } from '../components/Footer';
import { useAppSelector } from '../redux/hooks';
import { useNavigate } from 'react-router-dom'

export const Apps = () => {

    const navigate = useNavigate()
    const { nombre, acceso_Pantallas } = useAppSelector((state) =>  state.auth.userData!)
    const isDevEnvironment = window.location.origin === 'http://localhost:3000';

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const handleKeyDown = (e: any) => {
        if  ( (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 97 && e.keyCode <= 122) ) {

            if (acceso_Pantallas.some((i)=>i.pantalla_Acceso ==='ingreso-garita'||i.pantalla_Acceso==='all')) navigate('ingreso-garita')
            
        }
    }

    const apps = [
        {
            name: 'Crear Accesos',
            icon: '/assets/create-access.png',
            url: 'crear-acceso'
        },
        {
            name: 'Anular Acceso',
            icon: '/assets/deny-access.png',
            url: 'anular-acceso'
        },
        {
            name: 'Ingreso Garita',
            icon: '/assets/ingreso.png',
            url: 'ingreso-garita'
        },
        {
            name: 'Egreso Garita',
            icon: '/assets/egreso.png',
            url: 'egreso-garita'
        },
        {
            name: 'Perfil de Usuario',
            icon: '/assets/user.png',
            url: 'perfil-usuario'
        },
        {
            name: 'Historial de Accesos',
            icon: '/assets/history.png',
            url: 'historial-accesos'
        },
        {
            name: 'Estado de Cuenta',
            icon: '/assets/estado-cuenta.png',
            url: 'estado-cuenta'
        },
        {
            name: 'Pagos Recibidos',
            icon: '/assets/pagos-recibidos.png',
            url: 'pagos-recibidos'
        },
        {
            name: 'FOTOS',
            icon: 'https://cdn-icons-png.flaticon.com/512/38/38967.png',
            url: 'fotos-pruebas'
        },
        {
            name: 'Lectura de Agua',
            icon: '/assets/water-drop.png',
            url: 'lectura-agua'
        },
        {
            name: 'Consumo de Agua',
            icon: '/assets/water-meter.png',
            url: 'historico-agua'
        },
        {
            name: 'Reservas',
            icon: '/assets/calendar.png',
            url: 'reservas'
        },
        {
            name: 'Control de Inmuebles',
            icon: '/assets/home-manager.png',
            url: 'control-inmuebles'
        },
        {
            name: 'Encuestas',
            icon: 'https://img2.freepng.es/20180417/kuw/kisspng-computer-icons-questionnaire-survey-methodology-fo-swimming-5ad5db6506b8f8.7908891215239647730275.jpg',
            url: 'encuestas'
        },
        {
            name: 'Incidentes',
            icon: '/assets/exclamacion.png',
            url: 'crear-incidente'
        },
    ];
    const devURLs = [
        'fotos-pruebas',
    ];

    return (
        <>
        <Typography variant="h4" component="h1" gutterBottom textAlign='center' marginTop={5} >
                Bienvenido {nombre}
        </Typography>
        <Grid container justifyContent="center" alignItems="center" minHeight={'50vh'} paddingBottom={20} 
            sx={{
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
        >

            {apps.map((app) => ( acceso_Pantallas.some((pantalla) => pantalla.pantalla_Acceso === app.url
                || pantalla.pantalla_Acceso === 'all' ) && !devURLs.includes(app.url) &&
                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} item xs={6} sm={4} md={4} lg={2} xl={2}
                    key={app.name}
                >
                    <AppBtn name={app.name} icon={app.icon} url={app.url} />
                </Grid>
            ))}
            {/* Display development apps: */}
            {isDevEnvironment && apps.map((app) => ( devURLs.includes(app.url) &&
                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} item xs={6} sm={4} md={4} lg={2} xl={2}
                    key={app.name}
                >
                    <AppBtn name={app.name} icon={app.icon} url={app.url} />
                </Grid>
            ))}

        </Grid>
        <Footer/>
        </>
    )
}
