import React, { useEffect, useState } from 'react'
import { Box, Typography, Button } from '@mui/material';
import { IAmenidad, IEventoCalendario } from '../../models/reservas';
import { getTodayDate, yyyyMMddToDDmmYYYY } from '../../utils/date'
import { Confirm } from '../../components/Confirm';
import { Alert } from '../../components/Alert';

interface IRevisionReservaProps {
  amenidad: string|number;
  amenidadesList: IAmenidad[];
  eventos: IEventoCalendario[];
  clearAction: () => void;
  setReservaError: (idReserva: number) => void;
  nextStep?: () => void;
  nextText?: string;
  prevStep?: () => void;
  prevText?: string;
  reservaAction?: (evento) => (Promise<any>);
}

const getHourFromDate = (date:string=""):number => {
  if (date.length === 0) return 0;
  // date as YYYY-MM-DDTHH:mm:ss
  const time = date.split('T')[1]; // time as HH:mm:ss
  const hour = time.split(':')[0].trim();
  return !isNaN(parseInt(hour))? parseInt(hour) : 0
}

export const RevisionReserva = ({
  amenidad,
  amenidadesList, eventos,
  nextStep, nextText,
  prevStep, prevText,
  reservaAction,
  clearAction,
  setReservaError
}: IRevisionReservaProps) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [makeReserva, setMakeReserva] = useState<boolean>(false);
  const lastEvent = eventos[eventos.length - 1];
  const [showAlert, setShowAlert] = useState(false);
  const [alertOkText, setAlertOkText] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('info');
  const eventoData = {
    servicio: Number(amenidadesList.find((elem:IAmenidad) => Number(elem.servicio) === Number(amenidad))?.servicio),
    fecha: getTodayDate().split('T')[0],
    fecha_Reservacion: lastEvent?.start.split('T')[0],
    inicio_Amenidad: getHourFromDate(lastEvent?.start),
    fin_Amenidad: getHourFromDate(lastEvent?.end)
  }
  const selectedAmenidad = amenidadesList.find(
    (elem:IAmenidad) => Number(elem.servicio) === Number(amenidad)
  );
  const LAST_STEP = true;

  const appAlert = (message:string, type:string = 'info', okText:string = '') => {
    setShowAlert(true);
    setAlertOkText(okText);
    setAlertType(type);
    setAlertMessage(message);
  }

  const handleReserva = async () => {
    if (makeReserva) {
      if (reservaAction) {
        const result = await reservaAction(eventoData);
        if (result) {
          console.log('Reserva realizada con exito');
          console.log(`result = ${JSON.stringify(result, null, 2)}`);
          clearAction();
          setReservaError(result?.iD_reserva)
          nextStep && nextStep();
        } else {
          appAlert(
            "Algo salio mal. No se pudo realizar la Reserva",
            "error"
          );
        }
      } else {
        appAlert("No se ha podido realizar la reserva", "error");
      }
    }
  }

  useEffect(() => {
    console.log(`makeReserva = ${makeReserva}`);
    if(makeReserva)
      handleReserva()
  }, [makeReserva])

  return (
    <>

      <Confirm
        message='Desea realizar la reserva con los datos proporcionados?'
        okText='Reservar'
        okAction={() => setMakeReserva(true)}
        show={confirm}
        setShow={setConfirm}
      />

      <Alert
        message={alertMessage}
        okText={alertOkText}
        show={showAlert}
        setShow={(show:boolean) => setShowAlert(show)}
        type={alertType}
      />

      <Box>
        <Typography variant="h6">
          Revision de Datos:
        </Typography>
      </Box>
      <Box
          display='flex'
          justifyContent={'flex-end'}
          flexDirection={'column'}
          width={'calc(100% - 2rem)'}
          marginY={'1em'}
          boxShadow={'0px 0px 20px 4px rgba(140,140,140,0.4)'}
          borderRadius={'0.5rem'}
        >
          <Typography variant="body2"
            fontSize={'1rem'}
            component="div"
            gutterBottom marginY='1em' marginX={'1em'} marginTop={'2rem'} textAlign={'left'}
          >
            Amenidad: {selectedAmenidad && selectedAmenidad?.nombre}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            gutterBottom marginY='1em' marginX={'1em'} textAlign={'left'}
            fontSize={'1rem'}
          >
            Precio: {amenidadesList.find(ameni => ameni.servicio === Number(amenidad))?.valor?.toFixed(2)}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            gutterBottom marginY='1em' marginX={'1em'} textAlign={'left'}
            fontSize={'1rem'}
          >
            Fecha Reservada: {yyyyMMddToDDmmYYYY(lastEvent?.start, '/')}
          </Typography>
          <Typography variant="body2" component="div" gutterBottom marginY='1em'
            marginX={'1em'} marginBottom={'2rem'} textAlign={'left'}
            fontSize={'1rem'}
          >
            Nombre del Evento: {lastEvent && lastEvent?.title}
          </Typography>
        </Box>
      <Box
        display={'flex'}
        justifyContent={prevStep && nextStep ? 'space-between' : 'center'}
        position={'fixed'}
        bottom={0}
        left={0}
        width={'calc(100vw - 2rem)'}
        borderTop={'1px solid #AAA'}
        paddingY={'1rem'}
        bgcolor={'white'}
        zIndex={30}
        paddingX={'1rem'}
      >
        {prevStep &&
        <Button color='primary' variant="outlined" sx={{width:'40%', marginX: '0.5rem'}} onClick={prevStep}>
            {prevText}
        </Button>}
        {nextStep &&
        <Button
          color='primary' variant="outlined"
          sx={{width:'40%', marginX: '0.5rem'}}
          onClick={nextStep}
          style={{ display: `${LAST_STEP ? 'none' : 'initial'}` }}
        >
            {nextText}
        </Button>}
        {reservaAction &&
        <Button color='primary' variant="contained" sx={{width:'40%', marginX: '0.5rem'}} onClick={() => setConfirm(true)}>
            Reservar
        </Button>}
      </Box>
    </>
  )
}