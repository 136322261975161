import React from 'react';
import './DataCard.css';

interface IData {
  titulo: string;
  fecha: string;
  descripcion: string;
  link_src?: string;
  link_text?: string;
  target?: string;
}

interface IDataCardProps {
  data: IData;
  onClick?: () => void | undefined;
  variant?: string;
}

export const DataCard = ({ data, onClick, variant }: IDataCardProps) => {
  return (
    <div className={`DataCard ${variant ? variant : ''}`} onClick={onClick ? onClick : () => console.log('clicked')}>
      <div className='DataCard__Titulo'>
        {data.titulo}
      </div>
      <div className='DataCard__Fecha'>
        {data.fecha}
      </div>
      <div className='DataCard__Descripcion'>
        {data.descripcion}
      </div>
      <div className='DataCard__Footer'>
        {data.link_src &&
          <a href={data.link_src} target={data.target ? data.target : '_blank'} rel='noreferrer'>
            {data.link_text ? data.link_text : data.link_src}
          </a>}
      </div>
    </div>
  )
}