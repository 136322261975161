import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  await OneSignal.init({ appId: 'b28caa59-8f9c-448c-9fe8-be6caf55ec97', allowLocalhostAsSecureOrigin: true, notifyButton: { enable: true } });
  let res = await OneSignal.showSlidedownPrompt();
  console.log(res);
}

export async function getUserID() {

  let userId = await OneSignal.getUserId();
  console.log("OneSignal User ID:", userId);

  return userId;


}

export async function checkNotificationPermission() {

  let isSubscribed = await OneSignal.isPushNotificationsEnabled();

  if (isSubscribed) {
    console.log("User is subscribed.");
  }
  else {
    console.log("User is not subscribed.");
  }


  return isSubscribed;

}