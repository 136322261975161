import React from 'react'
import { TableCell as MuiTableCell } from '@mui/material'


export const TableCell = ( {children,sx}: any ) => {

  return (
    <MuiTableCell sx={{ fontFamily: 'Source Sans Pro', fontSize: 16,  ...sx}}>
        {children}
    </MuiTableCell>
  )
}
