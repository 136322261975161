import lsApi from "../api/lsApi";
import { IHistoricoLecturasAgua, ILecturaAgua, IPutLecturaCodeResponse } from '../models/lecturas';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { updateItem } from '../redux/readSlice';

export function useLecturas() {

    const userData = useAppSelector((state) => state.auth.userData)
    const dispatch = useAppDispatch()


    const getLecturas = async () => {

        try{
            let { data, status } = await lsApi.get<ILecturaAgua[]>('/api/ControlAcceso/ListadoLecturaDeAgua')
            if (status === 200) {
                //Revisar si hay lecturas en localstorage
                let lecturas = localStorage.getItem('lecturasToSync')
                if (lecturas) {
                    let lecturasArr = JSON.parse(lecturas)
                    // Revisar si hay lecturas en el servidor que esten en localstorage, si existe remplazar lectura en el servidor por la de localstorage
                    lecturasArr.forEach((l: ILecturaAgua) => {
                        if (data.some((d: ILecturaAgua) => d.casa === l.casa)) {
                            data = data.map((d: ILecturaAgua) => {
                                if (d.casa === l.casa) {
                                    return l
                                }
                                else{
                                    return d
                                }
                            })
                        }
                    })
                }
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }

    }

    const getHistorico = async () => {

        try{
            const { data, status } = await lsApi.get<IHistoricoLecturasAgua[]>('/api/ControlAcceso/HistorialLecturasAgua')
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }

    }

    const sendLectura = async (lectura : ILecturaAgua) => {

        const lecturaToSend = {
            empresa: lectura.empresa,
            agencia: lectura.agencia,
            id_Carga: lectura.id_Carga,
            id_Casa: lectura.casa,
            id_Linea: lectura.linea,
            lect_Actual: lectura.lect_Actual,
            usuario: userData?.usuario,
            num_Casa: lectura.num_Casa,
            lect_Anterior: lectura.lect_Anterior,
        }

        if (!navigator.onLine) {
            //Guardar lecturaToSend en localstorage si no hay internet
            let lecturasArr = [] as typeof lecturaToSend[]
            let lecturas = localStorage.getItem('lecturasToSync')
            console.log(lecturas !== null)

            if (lecturas !== null) {
                let lecturasParsed = JSON.parse(lecturas)
                //Revisar si ya existe una lectura para la casa en localstorage
                if (lecturasParsed.some((l: ILecturaAgua) => l.casa === lecturaToSend.id_Casa)) {
                    //Actualizar lectura en localstorage
                    lecturasArr = lecturasParsed.map((l: ILecturaAgua) => {
                        if (l.casa === lecturaToSend.id_Casa) {
                            return lecturaToSend
                        }
                        else{
                            return l
                        }
                    })
                }
                else{
                    lecturasArr = [...lecturasParsed, lecturaToSend]
                }
            }  
            else{
                lecturasArr.push(lecturaToSend)
            }
            localStorage.setItem('lecturasToSync', JSON.stringify(lecturasArr))
            return "offline"
        }

        try{
            const { data, status } = await lsApi.put<IPutLecturaCodeResponse>('/api/ControlAcceso/ActualizarLectAgua',lecturaToSend)
            if (status === 200) {
                return data
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }

    }

    const syncLecturas = async () => {
        let lecturasArr 
        let lecturas = localStorage.getItem('lecturasToSync')
        if (lecturas) {
            lecturasArr = JSON.parse(lecturas)
            if (lecturasArr.length > 0) {
                alert('Sincronizando lecturas')
                lecturasArr.forEach(async (lectura: ILecturaAgua) => {
                    try{
                        const { data, status } = await lsApi.put<IHistoricoLecturasAgua[]>('/api/ControlAcceso/ActualizarLectAgua',lectura)
                        if (status === 200) {
                            //remove from localstorage
                            lecturasArr = lecturasArr.filter((l: ILecturaAgua) => l.id_Carga !== lectura.id_Carga)
                            localStorage.setItem('lecturasToSync', JSON.stringify(lecturasArr))
                            console.log(lectura)
                            dispatch(updateItem({
                                ...lectura,
                                estado: 'Guardado'
                            }))
                        }
                    }
                    catch (error) {
                        return false
                    }
                })
            }
        }
    }

    return {
        getLecturas,
        getHistorico,
        sendLectura,
        syncLecturas
    }
}