import React, { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material';
import { useLecturas } from '../hooks/useLecturas';
import { CustomModal } from '../components/Modal';
import { LecturaAguaItem } from '../components/LecturaAguaItem';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setReadingsData } from '../redux/readSlice';
import { Container } from '@mui/system';

export const LecturaAgua = () => {
    
    const itemsPerPage = 5
    const { getLecturas, syncLecturas } = useLecturas()

    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [page, setPage] = useState(0)

    const { readingsData } = useAppSelector((state) => state.readings)
    const dispatch = useAppDispatch()

    useEffect(() => {
        (async () => {
            const lecturas = await getLecturas()
            if (lecturas) {
                dispatch(setReadingsData(lecturas))
            }
            else{
                setModalMessage('Error al obtener el listado de lecturas')
                handleOpen()
            }
            if (navigator.onLine) await handleSyncLecturas()
        })()

        window.addEventListener('online', handleSyncLecturas)
        return () => window.removeEventListener('online', handleSyncLecturas)

    }, [])

    const handleSyncLecturas = async () => {
        return await syncLecturas()
    }

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
    };

    return (
        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>
            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Lectura de Agua
            </Typography>

            {readingsData && readingsData.length > 0 && readingsData.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((lectura) => (
                <LecturaAguaItem lectura={lectura} key={lectura.num_Casa} />
            ))}

            <Container >

                <Button color='primary' sx={{margin:4 , width:'25%'}} onClick={() => setPage(page - 1)} disabled={page === 0} >
                    Pagina Anterior
                </Button>

                <Button color='primary' sx={{margin:4 , width:'25%'}} onClick={() => setPage(page + 1)} disabled={page+1 === Math.ceil(readingsData.length / itemsPerPage)} >
                    Pagina Siguiente
                </Button>

            </Container>

            <CustomModal openState={openModal} onCloseHandler={handleClose} >

                <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                    {modalMessage}
                </Typography>

                <Button color='secondary' variant="outlined"  sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                    OK
                </Button>

            </CustomModal>
            
        </Box>
    )
}
