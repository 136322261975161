import ReactDOM from 'react-dom';
import React, { Fragment } from 'react';
import { formatearNumero } from '../utils/numbers';
import ReactPDF, { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { IPaymentStatement } from '../models/payments';


interface EstadoPDFProps {
    estadoData: IPaymentStatement[]
    propietario: string
    casa: string
    strFecha: string
}

export const EstadoPDF = (props: EstadoPDFProps) => {
    
    const { estadoData, propietario, casa, strFecha } = props

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf', fontWeight: 400 },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
        ]
    })

    const styles = StyleSheet.create({

        page: { fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column', fontFamily: 'Open Sans' },

        spaceBetween: { flex : 1, flexDirection: 'row', alignItems:'center', justifyContent:'space-between', color: "#3E3E3E" },

        titleContainer: { flexDirection: 'row', marginTop: 24 },

        anchorToBottom: { position: 'absolute', bottom: 30, left: 40, right: 40 },
        
        imageContainer: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' },

        logo: { width: 100, height: 100 },

        footerImage: { width: 150 },
        
        alignRight: { textAlign: 'right' },
        
        title: { fontSize: 16,  textAlign: 'center', width: '100%' },
        
        details: { fontSize: 11, fontWeight: 'bold' }, 
        
        cell: { fontSize: 10, paddingTop: 4, paddingLeft: 7, paddingRight: 7, borderColor : 'grey', borderBottomWidth:1, borderRightWidth:1 },
        
        boldCell: { fontWeight: 'extrabold' },

        theader: { marginTop : 20, backgroundColor : '#FFCC00',  borderRightWidth:1, borderTopWidth:1 },

        theader1: { flex:1, borderLeftWidth:1 },

        theader2: { flex:5 },

        theader3: { flex:1 },

        tbody1: { flex:1, borderLeftWidth:1 },

        tbody2: { flex:5 },

        tbody3: { flex:1 },



    })

    const Header = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                {/*<Image style={styles.logo} src={logo} />*/}
                <Text style={styles.title}>Diceros</Text>
            </View>
        </View>
    )

    const Details = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.title}>Estado de Cuenta </Text>
                    <Text style={styles.details}>Propietario: {propietario} </Text>
                    <Text style={styles.details}>Casa {casa}</Text>
                    <Text style={styles.details}>Fecha: {strFecha}</Text>
                </View>
            </View>
        </View>
    )

    const TableHead = () => (
        <>
        <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <View style={[styles.theader, styles.theader1, styles.cell, styles.boldCell]}>
                <Text>Fecha</Text>   
            </View>
            <View style={[styles.theader, styles.theader2, styles.cell, styles.boldCell]}>
                <Text>Descripción</Text>
            </View>
            <View style={[styles.theader, styles.theader3, styles.cell, styles.boldCell]}>
                <Text>Monto</Text>   
            </View>
        </View>
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={[styles.tbody1, styles.cell, styles.boldCell]}>
                <Text></Text>   
            </View>
            <View style={[styles.tbody2, styles.cell, styles.boldCell]}>
                <Text>{estadoData[0].descripcion} </Text>   
            </View>
            <View style={[styles.tbody3, styles.cell, styles.boldCell, styles.alignRight]}>
                <Text>{formatearNumero(estadoData[0].type === 'abono' ? estadoData[0].monto*-1 : estadoData[0].monto)}</Text>   
            </View>
        </View>
        </>
    )

    const TableBody = () => (
        <>
        {estadoData.slice(1,-1).map((item)=>(
        <Fragment key={item.id}>
            <View style={{ width:'100%', flexDirection :'row'}}>
                <View style={[styles.tbody1, styles.cell]}>
                    <Text>{item.fecha}</Text>   
                </View>
                <View style={[styles.tbody2, styles.cell]}>
                    <Text>{item.descripcion} </Text>   
                </View>
                <View style={[styles.tbody3, styles.cell, styles.alignRight]}>
                    <Text>{formatearNumero(item.type === 'abono' ? item.monto*-1 : item.monto)}</Text>   
                </View>
            </View>
        </Fragment>
        ))}
        </>
    )

    const TableTotal = () => (
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={[styles.tbody1, styles.cell]}>
                <Text></Text>   
            </View>
            <View style={[styles.tbody2, styles.cell, styles.boldCell]}>
                <Text>Saldo</Text>   
            </View>
            <View style={[styles.tbody3, styles.cell, styles.boldCell, styles.alignRight]}>
                <Text>
                    {formatearNumero(estadoData[estadoData.length-1].type === 'abono' ? estadoData[estadoData.length-1].monto*-1 : estadoData[estadoData.length-1].monto)}
                </Text>  
            </View>
        </View>
    )

    const Footer = () => (
        <View style={[styles.titleContainer, styles.anchorToBottom]}>
            <View style={[styles.spaceBetween, styles.imageContainer]}>
                <Image style={styles.footerImage} src='/assets/DicerosLogo2.png' />
            </View>
        </View>
    )
  
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header  />
                <Details/>
                <TableHead/>
                <TableBody/>
                <TableTotal/>
                <Footer/>
            </Page>
        </Document>
    )

}
