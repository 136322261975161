import React from 'react'
import QRCode from "qrcode.react";
import { Button, Typography } from '@mui/material'
import { Abc, LocationOn, QrCode } from '@mui/icons-material';
import { shareQR, shareLocationUrl, shareText } from '../utils/share';
import useLocation from '../hooks/useLocation';

interface ShareCodeProps {
    accessCode : string
    message : string
}

export const ShareCode = (props: ShareCodeProps) => {

    const { accessCode, message } = props

    const {urlLocation} = useLocation()

    const shareQRCode = () => {
        const canvas = document.getElementById("qrCode") as HTMLCanvasElement
        const ctx = canvas.getContext('2d')
        if (ctx) {
            ctx.font = "bold 3px Arial"
            ctx.fillStyle = "black"
            ctx.textAlign = "center"
            ctx.textBaseline = 'middle'
            ctx.fillText(accessCode, 15,28)
        }

        if (canvas) {
            shareQR(canvas, message)
        }
    }

    const shareLocation = () => {
        const canvas = document.getElementById("qrCode") as HTMLCanvasElement
        // const ctx = canvas.getContext('2d')
        // if (ctx) {
        //     ctx.font = "bold 3px Arial"
        //     ctx.fillStyle = "black"
        //     ctx.textAlign = "center"
        //     ctx.textBaseline = 'middle'
        //     ctx.fillText(accessCode, 15,28)
        // }
        if (canvas) {
            shareLocationUrl(urlLocation, canvas, message)
        }
    }

    return (
        <> 

            <QRCode
                id="qrCode"
                size={300}
                value={accessCode}
                includeMargin={true}
            />

            <Typography variant="h5" component="div" gutterBottom>
                {accessCode}
            </Typography>

            <Button color='secondary' variant="contained" endIcon={<QrCode/>} sx={{marginY:2 , width:'50%'}} onClick={shareQRCode} >
                Compartir Codigo QR
            </Button>

            <Button color='secondary' variant="contained" endIcon={<Abc/>} sx={{marginY:2 , width:'50%'}} onClick={() => shareText(message)} >
                Compartir Codigo
            </Button>

            <Button color='secondary' variant="contained" endIcon={<LocationOn/>} sx={{marginY:2 , width:'50%'}} onClick={shareLocation} >
                Compartir Ubicacion
            </Button>

        </>
    )
}
