import { Box, Typography, TextField, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Check, Start } from '@mui/icons-material';
import { IVerifyCodeResponse } from '../models/codes';
import useAccessCode from '../hooks/useAccessCode';

interface VerificarAccesoProps {
    accessCode : string
    verifyCodeResponse : IVerifyCodeResponse
    setAccessCode : (value : string) => void
    setModalMessage : (value : string) => void
}

export const VerificarAcceso = (props:VerificarAccesoProps) => {

    const {accessCode, verifyCodeResponse, setAccessCode, setModalMessage} = props
    const {verifyAccesCode } = useAccessCode()
    const documento = useRef('')
    const [lecturaFlag, setLecturaFlag] = useState(false)
    const [lecturaMsg, setLecturaMsg] = useState('')
    const [lecturaLoading, setLecturaLoading] = useState(false)

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])
    

    const handleKeyDown = (e: any) => {
        
        if ( lecturaMsg !== 'Leyendo documento...') setLecturaMsg('Leyendo documento...')
            
        const keyCode = e.keyCode
        if  ( keyCode >= 32 && keyCode <= 220) {
            documento.current += e.key
        }
        else if (keyCode === 13) {
            const separador = documento.current.includes('|') ? '|' : documento.current.includes(']') ? ']' : ''

            if (separador !== '') {
                documento.current = documento.current.split(separador).slice(0, -1).join('|')
                documento.current = documento.current.replace(/([A-Za-z])0209([A-Za-z])/g, '$1Ñ$2').replace(/([A-Za-z])0241([A-Za-z])/g, '$1ñ$2')
                setLecturaFlag(true)
            }
            else {
                setLecturaMsg('Formato incorrecto en la lectura del documento, omita la lectura o intente de nuevo.')
                documento.current = ''
            }
        }
    }

    const handleSubmit = async (e : any) => {
        e.preventDefault()
        const {placa, vehiculo, color, documento} = e.target.elements
      
        let entrada = await verifyAccesCode( verifyCodeResponse.correlativo, placa.value, vehiculo.value, color.value, documento.value)
        if (entrada && entrada.success) {
            setModalMessage('Ingreso Correcto')
        }
        else if (entrada){
            setModalMessage(entrada.message)
        }
        else {
            setModalMessage('Error de sistema al dar el ingreso')
        }
        setAccessCode('')

    }

    return (

        <Box component='form' onSubmit={handleSubmit}>

        { !lecturaFlag 
        ?<> 
            <Typography id="modal-modal-title" variant="h6">
                Escanea la licencia de conducir 
            </Typography>

            <img src='/assets/id-card.png' alt='lectura' style={{width:'60%', height:'60%'}}/>

            <Typography id="modal-modal-title" variant="h6">
                {lecturaMsg}
            </Typography>

            <Button color='info' variant="contained" endIcon={<Start/>}  sx={{margin:2 , width:'50%', marginTop : 4}} onClick={()=>setLecturaFlag(true)}>
                Omitir
            </Button>

        </> 
        :<>    
            <Typography id="modal-modal-title" variant="h6">
                Codigo de Acceso : {accessCode} 
            </Typography>

            <TextField fullWidth label="Nombre Visita" defaultValue={verifyCodeResponse.nombre_Visita} disabled  sx={{marginY:2}} />
            <TextField fullWidth label="Motivo Visita" defaultValue={verifyCodeResponse.motivo_Visita} disabled sx={{marginY:2}} />
            <TextField fullWidth label="Casa" defaultValue={verifyCodeResponse.num_Casa} disabled sx={{marginY:2}} />
            <TextField fullWidth label="Fecha Visita" defaultValue={verifyCodeResponse.fecha_Visita} disabled sx={{marginY:2}} />
            <TextField fullWidth label="Nota" defaultValue={verifyCodeResponse.nota} disabled multiline rows={2} sx={{marginY:2}} />

            <TextField fullWidth name='placa' label="No. Placa" variant="outlined" sx={{marginY:2}} />

            <TextField fullWidth name='vehiculo' label="vehiculo" variant="outlined" sx={{marginY:2}} />

            <TextField fullWidth name='color' label="Color" variant="outlined" sx={{marginY:2}} />

            <TextField fullWidth name='documento' label="No. Documento" variant="outlined" sx={{marginY:2}} defaultValue={documento.current} />

            <Button color='success' variant="contained" type='submit' endIcon={<Check/>}  sx={{margin:2 , width:'50%'}} >
                OK
            </Button>
        </>
        }
        </Box>
    )
}
