import axios from 'axios';

const baseURL = 'https://diceros.ls-sys.com:4443';

const lsApi = axios.create({ baseURL });

lsApi.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers!['Authorization'] = `Bearer ${token}`;
        }
        
        return config;
    },
    error => {
        Promise.reject(error)
    }
);



export default lsApi;