import lsApi from "../api/lsApi";
import { IEncuesta, IListEncuesta } from '../models/encuestas';

export function useEncuestas() {

    const getListEncuestas = async () => {

        try {
            let { data, status } = await lsApi.get<IListEncuesta[]>('/api/ControlAcceso/ListadoEncuestas')
            if (status === 200) {
                return data;
            }
            else{
                return [
                  {
                    formulario: -1,
                    nombre: `Error-Status: ${status}`,
                    fecha: '',
                    descripcion: '',
                    desde: '',
                    hasta: '',
                    encuesta: -1
                  }
                ]
            }
        }
        catch (error) {
            console.error(error);
            return [
              {
                formulario: -1,
                nombre: 'Error!',
                fecha: '',
                descripcion: '',
                desde: '',
                hasta: '',
                encuesta: -1
              }
            ]
        }
    };

    const getDetalleEncuesta = async (encuestaId: number) => {
      try {
        const { data, status } = await lsApi.get<IEncuesta[]>(`/api/ControlAcceso/DetalleEncuesta?INFORMULARIO=${encuestaId}`);
        if (status === 200) {
          return data;
        } else {
          return []
        }
      } catch (err) {
        console.error(err);
        return []
      }
    };

    return {
      getListEncuestas,
      getDetalleEncuesta
    }
}