import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, Button, TableHead, TableRow, Typography } from '@mui/material';
import { Download } from '@mui/icons-material';
import { usePagosRecibidos } from '../hooks/usePagosRecibidos';
import { IReceivedPayments } from '../models/payments';
import { TableHeaderCell } from '../components/Table/TableHeaderCell';
import { TableCell } from '../components/Table/TableCell';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PagosPDF } from '../components/PagosPDF';

export const PagosRecibidos = () => {
  const { getPagosRecibidos, propietario, casa } = usePagosRecibidos();
  const [pagosRecibidosData, setPagosRecibidosData] = useState<IReceivedPayments[]>([]);
  const errorPagosRecibidosObject: IReceivedPayments = {
    id: 'error-ocurred',
    fecha: '00/00/0000',
    num_Boleta: `Ocurrió un error`,
    valor: 0
  };

  useEffect(() => {
    (async () => {
      const pagosRecibidos = await getPagosRecibidos();
      if (pagosRecibidos) {
        setPagosRecibidosData(pagosRecibidos);
      } else {
        console.log('No se pudo obtener pagos recibidos');
        setPagosRecibidosData([errorPagosRecibidosObject]);
      }
    })();
  }, []);

  const handleDownload = () => {
    alert('Aún estamos trabajando en esta función...');
  };

  return (
    <Box textAlign="center" display="flex" alignItems="center" flexDirection='column' paddingBottom={'10rem'}>
      <Typography variant="h4" component="div" gutterBottom marginY='1em'>
        Pagos Recibidos
      </Typography>

      <Typography component="div" gutterBottom textAlign='left' width={'100%'} style={{ textIndent: '2rem' }} marginY='1rem'>
        {casa} - {propietario}
      </Typography>

      <Box display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={800} >
        <Table stickyHeader>
          <TableHead>
              <TableRow >
                  <TableHeaderCell>Fecha</TableHeaderCell>
                  <TableHeaderCell>No. Recibo</TableHeaderCell>
                  <TableHeaderCell>Monto</TableHeaderCell>
              </TableRow>
          </TableHead>
          <TableBody>
            {pagosRecibidosData.length > 0 && pagosRecibidosData.map(data => (
              <TableRow key={Number(data.id) === -1 ? Math.floor(Math.random() * 999) + 600 : data.id}>
                <TableCell>{data.fecha}</TableCell>
                <TableCell>{data.num_Boleta}</TableCell>
                <TableCell>{data.valor}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box textAlign="center" borderTop={'1px solid #AAA'} position="fixed" bottom='0' width={'100%'} style={{ backgroundColor: '#FFF' }}>
      {
        pagosRecibidosData.length > 0 &&
        <PDFDownloadLink document={<PagosPDF pagosData={pagosRecibidosData} propietario={propietario} casa={casa} />} fileName={`PagosRecibidos-Casa-${casa}.pdf`}>
        <Button color='secondary' variant="outlined" endIcon={<Download/>} sx={{marginY:2 , width:'50%'}}>
              Descargar
        </Button>
        </PDFDownloadLink>
      }
      </Box>
    </Box>
  );
}