import React, { useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { Home, LocationOn, Save } from '@mui/icons-material';
import useLocation from '../hooks/useLocation';
import useLogin from '../hooks/useLogin';
import { useAppSelector } from '../redux/hooks';
import { CustomModal } from '../components/Modal';
import { useNavigate } from 'react-router-dom';

export const Perfil = () => {

    const navigate = useNavigate()

    const [openModal, setOpenModal] = useState(false)
    const [returnHome, setReturnHome] = useState(true)
    const [modalMessage, setModalMessage] = useState('')
    const [tempFiscalName, setTempFiscalName] = useState()


    const { usuario,nombre,email,nit,longitud,latitud,telefono,nombre_fiscal,direccion_fiscal } = useAppSelector((state) =>  state.auth.userData!)
    const [ userLatitud, setUserLatitud ] = useState(latitud)
    const [ userLongitud, setUserLongitud ] = useState(longitud)

    const {latitude, longitude} = useLocation()
    const { updateUserData, verificarNit } = useLogin()

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
        if (returnHome)
            navigate('/home')
        else
            setReturnHome(true)
    }

    const handleSubmit = async (e:any) => {
        e.preventDefault()
        const { nombre,email,nit,longitud,latitud,telefono,direccionF } = e.target.elements

        let fixedNit = nit.value.toUpperCase()

        let nitVerification = await verificarNit(fixedNit,nombre.value)

        if (nitVerification) {
            let data = await updateUserData(nombre.value,email.value,fixedNit,longitud.value,latitud.value,telefono.value,nitVerification,direccionF.value)
            if (data) {
                setModalMessage('Datos actualizados correctamente')
            }
            else{
                setModalMessage('Error al actualizar datos')
                setReturnHome(false)
            }
        }
        else{
            setModalMessage('No se encontro nombre fiscal con el nit/CUI ingresado')
            setReturnHome(false)
        }
        handleOpen()
    }

    const handleVerficarNit = async (nit:string) => {
        let nitVerification = await verificarNit(nit.toUpperCase(),nombre)
        if (!nitVerification) {
            setModalMessage('No se encontro nombre fiscal con el nit/CUI ingresado')
            setReturnHome(false)
            handleOpen()
        }
        else{
            setTempFiscalName(nitVerification)
        }
    }

    const handleLocation = () => {
        setUserLatitud(latitude)
        setUserLongitud(longitude)
    }
    
    return (
        <Box textAlign="center" display="flex" alignItems="center" flexDirection='column'>

            <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                Perfil de Usuario
            </Typography>

            <Box component="form" display="flex" flexDirection="column" alignItems='center' width={0.8} maxWidth={500} onSubmit={handleSubmit} >

                <Typography variant="h6" component="div" gutterBottom alignSelf='start'>
                    Datos Personales
                </Typography>

                <TextField fullWidth required label="Codigo" defaultValue={usuario} disabled variant="outlined" sx={{marginY:2}} />

                <TextField fullWidth required label="Nombre" defaultValue={nombre} name='nombre' variant="outlined" sx={{marginY:2}} />

                <TextField fullWidth required label="Email" defaultValue={email} name='email' variant="outlined" sx={{marginY:2}} />

                <TextField fullWidth required label="No. Telefono" defaultValue={telefono} name='telefono' type='number' variant="outlined" sx={{marginY:2}} />

                <TextField fullWidth required label="CUI/NIT" helperText="Ingrese CF para Consumidor Final" defaultValue={nit} name='nit' variant="outlined" sx={{marginY:2}} onBlur={e => handleVerficarNit(e.target.value)} />

                <TextField fullWidth required label="Nombre Fiscal"  value={tempFiscalName||nombre_fiscal} name='nombreF' disabled variant="outlined" sx={{marginY:2}} />

                <TextField fullWidth required label="Direccion Fiscal" defaultValue={direccion_fiscal} name='direccionF' variant="outlined" sx={{marginY:2}} />

                <Typography variant="h6" component="div" gutterBottom alignSelf='start'>
                    Ubicación de Domicilio
                </Typography>

                <Button color='primary' variant="text" endIcon={<LocationOn/>} sx={{marginY:2 , width:'50%'}} onClick={handleLocation} > Usar Ubicación Actual </Button>

                <TextField fullWidth required label="Longitud" value={userLongitud} name='longitud' disabled variant="outlined" sx={{marginY:2}} />

                <TextField fullWidth required label="Latitud" value={userLatitud} name='latitud' disabled variant="outlined" sx={{marginY:2}} />

                <Button type='submit' color='secondary' size='large' variant="contained" endIcon={<Save/>} sx={{marginY:2 , width:'50%'}} > Actualizar </Button>

            </Box>

            <CustomModal
                openState={openModal}
                onCloseHandler={handleClose}
            >
                <Typography variant="h4" component="div" gutterBottom marginY='1em'>
                    {modalMessage}
                </Typography>

                { returnHome 
                    ?<Button color='secondary' variant="outlined" endIcon={<Home/>}  sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                        Inicio
                    </Button>
                    : <Button color='secondary' variant="outlined" sx={{marginY:2 , width:'50%'}} onClick={handleClose} >
                        Aceptar
                    </Button>
                }

            </CustomModal>
            

        </Box>
    )
}
